import { List } from "@mui/material";
import NavigationItem from "./NavigationItem";
import { INavigation } from "./navigationInterfaces";
import { useAuthStore } from "../../../features/auth/authStore";

interface Props{
    items: INavigation[]
}
const NavigationList:React.FC<Props> = ({items}) => {
    const authUser = useAuthStore(state => state.authUser);
    return (
        <List>
            {items.map((item:INavigation) => {
                if(item.exclude?.includes(authUser?.role??'')) return null;

                return <NavigationItem item={item} key={item.name} />
            })}
        </List>
    )
}

export default NavigationList;