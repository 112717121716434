import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface Props{
    selectedFile: File;
    handleCancelSelection: ()=>void;
}
const APFileUploaded:React.FC<Props> = ({selectedFile, handleCancelSelection}) => {
    return(
        <Box sx={{
            backgroundColor: (theme) => `${theme.palette.primary.main}10`,
            padding: '12px',
            borderRadius: '8px',
            width: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}>
                    <img src='/assets/icons/excel.png' alt='excel' width={32} height={32} />
                    <Box>
                        <Typography variant="body2">{selectedFile?.name}</Typography>
                        <Typography variant="body2">
                            {((selectedFile?.size || 0) / 1024).toFixed(2)} KB
                        </Typography>
                    </Box>
                </Box>
                <IconButton aria-label="close" size='small' sx={{ padding: 0 }} onClick={handleCancelSelection}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </Box>
        </Box>
    )
}

export default APFileUploaded;