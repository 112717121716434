import { Box, Typography } from "@mui/material";

interface Props{
    value?:string;
    clickable?:boolean;
    onClick?: () => void;
}
const APDefaultCell:React.FC<Props> = ({value, clickable=false, onClick}) => {
    return(
        <Box
            sx={{
              height: '100%',
              whiteSpace: 'wrap',
              textWrap:'wrap',
              alignContent: 'center',
              cursor: clickable?'pointer':'default'
            }}
            onClick={onClick}
          >
            <Typography>{value}</Typography>
          </Box>
    )

}

export default APDefaultCell;