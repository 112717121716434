import React, { useState } from 'react';
import JSZip from 'jszip';
import { Button, Box, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';

interface FileWithPreview {
  file: File;
  previewUrl: string;
  folderName: string;
  subFolderName: string;
}

const FolderUploader: React.FC = () => {
  const [filesWithPreviews, setFilesWithPreviews] = useState<FileWithPreview[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []).filter(file =>
      file.type.startsWith('image/')
    );

    // Create a map to group files by their folder and sub-folder names
    const groupedFiles: Record<string, Record<string, FileWithPreview[]>> = {};

    selectedFiles.forEach(file => {
      const previewUrl = URL.createObjectURL(file);
      const [folderName, subFolderName] = file.webkitRelativePath.split('/');

      if (!groupedFiles[folderName]) {
        groupedFiles[folderName] = {};
      }

      if (!groupedFiles[folderName][subFolderName]) {
        groupedFiles[folderName][subFolderName] = [];
      }

      groupedFiles[folderName][subFolderName].push({
        file,
        previewUrl,
        folderName,
        subFolderName
      });
    });

    // Flatten grouped files into an array for rendering
    const filesWithPreviews = Object.entries(groupedFiles).flatMap(([folderName, subFolders]) =>
      Object.entries(subFolders).flatMap(([subFolderName, files]) =>
        files.map(file => ({ ...file, folderName, subFolderName }))
      )
    );

    setFilesWithPreviews(filesWithPreviews);
  };

  const handleSave = async () => {
    const zip = new JSZip();

    // Add files to zip with folder structure
    filesWithPreviews.forEach(({ file, folderName, subFolderName }) => {
      zip.file(`${folderName}/${subFolderName}/${file.name}`, file);
    });

    // Generate the zip file
    zip.generateAsync({ type: 'blob' })
      .then(content => {
        // Send the zip file to the API
        const formData = new FormData();
        formData.append('file', content, 'images.zip');

        return fetch('https://your-api-endpoint.com/upload', {
          method: 'POST',
          body: formData,
        });
      })
      .then(response => response.json())
      .then(data => {
        console.log('Upload successful', data);
      })
      .catch(error => {
        console.error('Upload failed', error);
      });
  };

  return (
    <Box>
      <input
        type="file"
        accept="image/*"
        multiple
        // @ts-ignore
        webkitdirectory="true" // Allows folder uploads in Chrome-based browsers
        onChange={handleFileChange}
      />
      <Box mt={2}>
        {Object.entries(filesWithPreviews.reduce<Record<string, Record<string, FileWithPreview[]>>>((acc, fileWithPreview) => {
          const { folderName, subFolderName } = fileWithPreview;
          if (!acc[folderName]) {
            acc[folderName] = {};
          }
          if (!acc[folderName][subFolderName]) {
            acc[folderName][subFolderName] = [];
          }
          acc[folderName][subFolderName].push(fileWithPreview);
          return acc;
        }, {})).map(([folderName, subFolders]) => (
          <Box key={folderName} mb={4}>
            <Typography variant="h6">Parent Folder: {folderName}</Typography>
            {Object.entries(subFolders).map(([subFolderName, files]) => (
              <Box key={subFolderName} mb={2}>
                <Typography variant="subtitle1">Sub-Folder: {subFolderName}</Typography>
                <Grid container spacing={2}>
                  {files.map((fileWithPreview, index) => (
                    <Grid item xs={3} key={index}>
                      <Card>
                        <CardMedia
                          component="img"
                          image={fileWithPreview.previewUrl}
                          alt={`Preview ${fileWithPreview.file.name}`}
                          sx={{ height: 150, objectFit: 'cover' }}
                        />
                        <CardContent>
                          <Typography variant="caption">{fileWithPreview.file.name}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Button variant="contained" color="primary" onClick={handleSave} disabled={filesWithPreviews.length === 0}>
        Save
      </Button>
    </Box>
  );
};

export default FolderUploader;
