import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
// import * as XLSX from 'xlsx';
import { Control, Controller } from 'react-hook-form';
// import APFileModal from './ExcelFileInput/APFileModal';
import APFileUploaded from './ExcelFileInput/APFileUploaded';
import APDragAndDrop from './ExcelFileInput/APDragAndDrop';
// import { toast } from 'react-toastify';

interface Props {
    id?: string;
    label?: string;
    name: string;
    control: Control<any>;
    defaultValue?: File;
    rules?: object;
    width?: string;
    showModal?: boolean;
    isCircle?: boolean;
    type?: 'image' | 'excel';
}

const FileInput: React.FC<Props> = ({ id, label, name, control, defaultValue = null, rules, isCircle = false, type }) => {
    const [fileUploaded, setFileUploaded] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

    const handleFileChange = (file: File) => {
        setSelectedFile(file);
        processFile(file);
    };

    const processFile = (file: File) => {
        if (type === 'image') {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                setImagePreviewUrl(event.target.result); // Store the image preview URL
                setFileUploaded(true);
            };
            reader.readAsDataURL(file);
        }
        //  else if (type === 'excel') {
        //     const reader = new FileReader();
        //     reader.onload = (event: any) => {
        //         const binaryStr = event.target.result;
        //         const workbook = XLSX.read(binaryStr, { type: 'binary' });
        //         const sheetName = workbook.SheetNames[0];
        //         const sheet = workbook.Sheets[sheetName];
        //         const data = XLSX.utils.sheet_to_json(sheet);
        //         console.log(data); // Do something with the Excel data
        //         setFileUploaded(true);
        //     };
        //     reader.readAsBinaryString(file);
        // }
    };

    const handleCancelSelection = () => {
        setFileUploaded(false);
        setSelectedFile(null);
        setImagePreviewUrl(null);
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState }) => (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    {!fileUploaded ? (
                        <Box
                            id={id}
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: isCircle ? '100%' : '8px',
                                border: '1px dashed #ccc',
                                backgroundColor: '#FCFCFC'
                            }}
                        >
                            <APDragAndDrop
                                label={label}
                                isCircle={isCircle}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                                handleFileChange={handleFileChange}
                            />
                            {fieldState.error && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    {fieldState.error.message}
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{ width: '100%' }}>
                            {type === 'image' && imagePreviewUrl && (
                                <Box
                                    component="img"
                                    src={imagePreviewUrl}
                                    alt="Selected file"
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: isCircle ? '100%' : '8px',
                                    }}
                                />
                            )}
                            {type === 'excel' && <APFileUploaded selectedFile={selectedFile!} handleCancelSelection={handleCancelSelection} />}
                        </Box>
                    )}
                </Box>
            )}
        />
    );
};

export default FileInput;
