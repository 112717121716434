import { Box, useTheme } from "@mui/material";
import RequestInfo from "./RequestInfo";
import AssetsTableActions from "./AssetsTableActions";
import AssetsTable from "./AssetsTable";

const AssetsPage = () => {
    const theme = useTheme();

    return(
        <Box>
            <RequestInfo />
            <Box sx={{mt:'20px', padding:theme.spacing(3) }}>
                <AssetsTableActions />
                <Box sx={{mb:1}}/>
                <Box sx={{height:'100%'}}>
                <AssetsTable /> 

                </Box>
            </Box>
        </Box>
    )
}

export default AssetsPage;