import { AxiosResponse } from 'axios';
import { IConfirmEmail, IForgotPasswordForm, ILoginActionResponse, ILoginForm, IResetPasswordForm } from './authInterfaces';
import { CustomAxiosRequestConfig, appAPI } from '../../httpClient';

export const login = async (formValues: ILoginForm): Promise<AxiosResponse<ILoginActionResponse>> => 
  appAPI.post('/auth/login',formValues);
export const forgotPassword = async (formValues: IForgotPasswordForm) => appAPI.post('/auth/forgot_password', formValues);
export const resetPassword = async (formValues: IResetPasswordForm) => appAPI.post('/auth/reset_password', formValues,{ applyAuth: false} as CustomAxiosRequestConfig);
export const setPassword = async (formValues: IResetPasswordForm) => appAPI.post('/api/v1/users/accept_invite', formValues,{ applyAuth: false} as CustomAxiosRequestConfig);
export const confirmEmail = async (formValues: IConfirmEmail) => appAPI.post('/confirm-email', formValues,{ applyAuth: false} as CustomAxiosRequestConfig);
export const logout = async () => appAPI.post('/logout',{});