import * as React from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { styled } from '@mui/system';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const StyledTextValidator = styled(TextValidator)({
  '& .MuiInputBase-root': {
    backgroundColor: '#f0f0f0', // Grey background
    borderRadius: '8px',        // Rounded corners
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',             // Remove the border
  },
});

interface PasswordInputProps {
  id?: string;
  label: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validators?: string[];
  errorMessages?: string[];
  [x: string]: any;
}

const PasswordInput: React.FC<PasswordInputProps> = ({ id, label, name, value, onChange, validators, errorMessages, ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize:'12px', color:'#4F4F4F' }}>
        {label}
      </Typography>
      <StyledTextValidator
        id={id}
        variant="outlined"
        fullWidth
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={onChange}
        validators={validators}
        errorMessages={errorMessages}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Box>
  );
};

export default PasswordInput;
