import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { datadogRum } from '@datadog/browser-rum';
import 'react-material-symbols/outlined';

// datadogRum.init({
//   applicationId: '899ba96c-3128-4c10-8611-147bdd43aa4a',
//   clientToken: 'puba72f03b471e7f14b5c0b45065cb72897',
//   site: 'datadoghq.com',
//   service: 'appraisal-web', // Name of your application/service
//   env: 'staging', // Environment (e.g., 'production', 'staging')
//   version: '1.0.0', // Version of your application
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 100,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input',
// });

// datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
