export const DRAWER_WIDTH = 300;

export const ROLES = [
    {id:'super_admin',value:'super_admin',label:'Super Admin'},
    {id:'admin',value:'admin',label:'Admin'},
    {id:'appraiser',value:'appraiser',label:'Appraiser'},
    {id:'guest',value:'guest',label:'Guest'}
];

export const STATUS_FILTERS = [
    {id:'all',value:'All',label:'All'},
    {id:'new',value:'New',label:'New'},
    {id:'in-progress',value:'In progress',label:'In progress'},
    {id:'awaiting-feedback',value:'Awaiting feedback',label:'Awaiting feedback'},
    {id:'completed',value:'Completed',label:'Completed'},
];

export const USER_STATUS_FILTERS = [
    {id:'all',value:'all',label:'All'},
    {id:'acive',value:'active',label:'Active'},
    {id:'blocked',value:'blocked',label:'Blocked'},
    {id:'pending',value:'pending',label:'Pending'},
    {id:'locked',value:'locked',label:'Locked'},
];