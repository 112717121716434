import { toast } from "react-toastify";

export const handleError = (error:any) => {
    console.log('request error: ',error)
    let errMsg = error.response?.data.error;
    if(errMsg.includes('PG')) errMsg = 'An error occurred. Please try again.';
    else if(errMsg.includes('Token has expired')){
        localStorage.clear();
        window.location.href = '/login';
    }
    toast.error(errMsg ?? error.message ?? 'An error occurred. Please try again.')
}