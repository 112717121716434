import { Box, useTheme } from "@mui/material";
import AssetItem from "./AssetItem";
import { MaterialSymbol } from "react-material-symbols";
import AssetCompareTable from "./AssetCompareTable";
import AssetCompareTableActions from "./AssetCompareTableActions";
import { useMemo, useState } from "react";
import { ASSETS_DATA } from "../../data/assetsData";
import { useParams } from "react-router-dom";

const AssetComparePage = () => {
    const theme = useTheme();
    const {id} = useParams();
    const mainRow = useMemo(() => {
        return ASSETS_DATA.find((asset) => asset.serial === id) || null;
    }, [id]);
    const [selectedRow, setSelectedRow] = useState<any>(ASSETS_DATA[0]);

    const handleRowClick = (row:any) => {
        setSelectedRow(row);
    }

    return(
        <Box
            sx={{
                padding: theme.spacing(3),
                width:'100%',
                height:'100%',
                backgroundColor:'#FFFCFD'
            }}
        >
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mb:'40px'}}>
                <AssetItem 
                    image={"/assets/images/asset-1.png"} 
                    summary={"1984 Ford 600 Lube Truck"} 
                    location={"Hayden, CO"} 
                    valuationType={mainRow?.valuationType??""} 
                    serial={mainRow?.serial??""} 
                    milage={"10,311"} 
                    description={"5+2, 8.2L cat diesel"} 
                    type={"main"}                    
                />
                <MaterialSymbol icon="sync_alt" size={30} color={theme.palette.primary.main}/>
                <AssetItem 
                    image={"/assets/images/asset-1.png"} 
                    summary={"1984 Ford 600 Lube Truck"} 
                    location={"Hayden, CO"} 
                    valuationType={"Internal valuation"} 
                    serial={"1FDNR6JGJNKSMIKNM252"} 
                    milage={"10,311"} 
                    description={"5+2, 8.2L cat diesel"} 
                    type={"compare"}  
                    amount={selectedRow?.estimate??0}                 
                />
            </Box>
            <Box>
                <Box>
                    <AssetCompareTableActions />
                </Box>
                <AssetCompareTable onRowClick={handleRowClick}/>
            </Box>
        </Box>
    )

}

export default AssetComparePage;