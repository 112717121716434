import { Box } from "@mui/material";
import APStatus from "../APStatus";

interface Props{
    value?:string;
}
const APStatusCell:React.FC<Props> = ({value}) => {
    return(
        <Box sx={{
            height: '100%',
            alignContent: 'center'
          }}>
            <APStatus status={value!} />
          </Box>
    )
}

export default APStatusCell;