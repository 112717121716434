import * as React from 'react';
import { TextField, InputAdornment, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: 'white',
    borderRadius: '48px',  
    border: '1px solid #f0f0f0',
    height:'42px'
  },
});

interface Props{
  name?:string;
  onChange?:(val:any)=>void;
  isLoading?:boolean;
}
const SearchInput: React.FC<Props> = ({ name, onChange, isLoading }) => {
  return (
      <StyledTextField
        variant="outlined"
        fullWidth
        name={name}
        placeholder="Search"
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {isLoading ? <CircularProgress size={24}/>: <SearchIcon  sx={{fontSize:24}}/>}
            </InputAdornment>
          ),
        }}
      />
  );
};

export default SearchInput;
