import { create } from 'zustand';

interface GeneralStore {
  isPageTransition: boolean;
}

interface GeneralAction {
  setIsPageTransition: (value: boolean) => void;
}

export const useGeneralStore = create<GeneralStore & GeneralAction>()(
    (set) => ({
      isPageTransition:false,
      setIsPageTransition: (value: boolean) =>
        set(() => {
          return {
            isPageTransition: value,
          };
        }),
    })
);
