import { Button,CircularProgress } from "@mui/material";
import { ReactNode } from "react";

interface APIconButtonProps {
    text: string;
    icon: ReactNode;
    onClick?: (event:any) => void;
    whiteBackground?: boolean;
    color?: any;
    isRounded?:boolean;
    type?:"button" | "submit" | "reset" | undefined;
    isLoading?:boolean;
    isDisabled?:boolean;
}

const APIconButton: React.FC<APIconButtonProps> = ({ text, icon, onClick, whiteBackground = false, color='primary', isRounded='false', type, isLoading=false, isDisabled=false }) => {
    return (
        <Button
            variant="contained"
            onClick={onClick}
            endIcon={isLoading ? <CircularProgress color="inherit" size={20} sx={{marginRight:1}} />:icon}
            color={color}
            type={type}
            disabled={isLoading||isDisabled}
            sx={{
                fontSize:'14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                borderRadius: isRounded?'40px':undefined,
                height:'40px',
                width:'100%',
                border: (theme)=>whiteBackground ? `1px solid #EBEBEB` : undefined,
                backgroundColor: whiteBackground ? '#fff' : undefined,
                color: whiteBackground ? '#000' : undefined,
                '&:hover': {
                    backgroundColor: whiteBackground ? '#f0f0f0' : undefined,
                },
                boxShadow:0
            }}
        >
            {text}
        </Button>
    );
}

export default APIconButton;
