import * as React from 'react';
import MuiPagination from '@mui/material/Pagination';
import {
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { TablePaginationProps } from '@mui/material/TablePagination';

interface PaginationProps extends Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'> {}

function Pagination({ page, onPageChange, className }: PaginationProps) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}  // Adjust to make it 1-based for display
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1); // Adjust back to 0-based for DataGrid
      }}
    />
  );
}

export function APTablePagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}
