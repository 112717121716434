import { CustomAxiosRequestConfig, appAPI } from "../../httpClient";
import { IOrganizationFormValues } from "./organizationInterfaces";

export const getOrganizations = async()=>appAPI.get('/api/v1/organizations');
export const getOrganization = async(orgId:string)=>appAPI.get(`/api/v1/organizations/${orgId}`);
export const postOrganization = async(data:IOrganizationFormValues)=>{
    // const temData = {name:data.name,};
    // const formData = new FormData();
    // formData.append('name', data.name);
    // formData.append('admin', data.admin?.value);
    // if (data.file) {
    //     formData.append('file', data.file);
    // }

    return appAPI.post('/api/v1/organizations',{...data,email:'sasds@mail.com'},{
        // headers:{
        //     'Content-Type': 'multipart/form-data', 
        // }
    } as CustomAxiosRequestConfig);
}
export const patchOrganization = async(orgId:string,data:IOrganizationFormValues)=>{
    const temData = {name:data.name};
    // const formData = new FormData();
    // formData.append('name', data.name);
    // formData.append('admin', data.admin?.value);
    // if (data.file) {
    //     formData.append('file', data.file);
    // }
    return appAPI.patch(`/api/v1/organizations/${orgId}`,temData,{
        // headers:{
        //     'Content-Type': 'multipart/form-data', 
        // }
    } as CustomAxiosRequestConfig);
}
export const deleteOrganization = async(orgId:string)=>appAPI.delete(`/api/v1/organizations/${orgId}`);