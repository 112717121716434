import React from "react";
import { Box, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ValidatorForm } from "react-material-ui-form-validator";
import { toast } from "react-toastify";
import { LabelInput2 } from "../../components/common/inputs";
// import { useNavigate } from "react-router-dom";
import { forgotPassword } from "./authQueries";
import { IForgotPasswordForm } from "./authInterfaces";
import { DefaultButton } from "../../components/common/buttons";
import { handleError } from "../../utils/errorHandler";

const ForgotPasswordForm:React.FC = () => {
  // const navigate = useNavigate();
  const [formData, setFormData] = React.useState<IForgotPasswordForm>({
    email: '',
  });
  const [isSuccessful, setShowIsSuccessful] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const {isPending: isLoading, mutate: forgotPasswordMutation} = useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: (formValues: IForgotPasswordForm) => forgotPassword(formValues)
  });
  
  const handleSubmit = () => {
    forgotPasswordMutation(
      formData,{
        onSuccess(data){
          toast.success(data.data.message??'Email sent successfully. Check your email for further action');
          setShowIsSuccessful(true);
        },
        onError(error){
          handleError(error);
        }
      }
    );
  };
    return (
      <Box sx={{width:'100%', height:'100%'}}>
        { !isSuccessful && <Box sx={{width:'100%', height:'100%'}}>
            <Typography component="h1" variant="h6" sx={{ fontWeight:'bold' }}>
              Oops, it seems you forgot your password.            
            </Typography>
            <Typography variant="body1" sx={{ mb: 6,pr:10}}>
              Kindly provide your email address. A reset link will be sent to you shortly.            
            </Typography>
            <ValidatorForm onSubmit={handleSubmit}>
              <LabelInput2
                label="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Email is not valid']}
              />
              <DefaultButton 
                text="Submit"
                type="submit"
                isLoading={isLoading}
              />
            </ValidatorForm>
        </Box>}

        {
          isSuccessful && 
          <Box>
            <Typography component="h1" variant="h6" sx={{ fontWeight:'bold' }}>
              Great! recovery email sent            
            </Typography>
            <Typography variant="body1" sx={{ mb: 6,pr:10}}>
              A recovery email has been sent to {formData.email}.              
            </Typography>
          </Box>
        }

      </Box>
    )
}
export default ForgotPasswordForm;