import { faker } from '@faker-js/faker';

type Estimate = {
  name: string;
  estimate: number;
};

type Asset = {
  id:string;
  valuationType: 'Internal' | 'HeavyWorth';
  year: number;
  make: string;
  model: string;
  assetType: 'Truck' | 'Car';
  serial: string;
  stickerId: string;
  otherEstimates: Estimate[];
  myEstimate: number;
};

function getRandomValuationType(): 'Internal' | 'HeavyWorth' {
  return faker.helpers.arrayElement(['Internal', 'HeavyWorth']);
}

function getRandomAssetType(): 'Truck' | 'Car' {
  return faker.helpers.arrayElement(['Truck', 'Car']);
}

function generateOtherEstimates(): Estimate[] {
  const count = faker.number.int({ min: 0, max: 5 });
  return Array.from({ length: count }, () => ({
    name: faker.person.fullName(),
    estimate: faker.number.int({ min: 1000, max: 50000 }),
  }));
}

function generateAsset(): Asset {
  return {
    id:faker.string.alpha(16),
    valuationType: getRandomValuationType(),
    year: faker.date.past({years:20}).getFullYear(),
    make: faker.vehicle.manufacturer(),
    model: faker.vehicle.model(),
    assetType: getRandomAssetType(),
    serial: faker.vehicle.vin(),
    stickerId: faker.string.alpha(16),
    otherEstimates: generateOtherEstimates(),
    myEstimate: faker.number.int({ min: 1000, max: 50000 }),
  };
}

function generateAssets(count: number): Asset[] {
  return Array.from({ length: count }, generateAsset);
}

export const ASSETS_DATA = generateAssets(20);

