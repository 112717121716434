export const EVALUATION_REASONS = [
    {
        id: 'id-1',
        label: 'Liquidation',
        value: 'Liquidation'
    },
    {
        id:'id-2',
        label: 'Bankruptcy',
        value: 'Bankruptcy'
    },
    {
        id: 'id-3',
        label: 'Trade in',
        value: 'Trade in'
    }
];