import { Box, Typography, useTheme } from "@mui/material";
import { APIconButton } from "../../components/common/buttons";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from "react-router-dom";

const RequestInfo = () => {
    const theme = useTheme();
    const navigate = useNavigate();

     const renderTitle = (title:string, sub:string)=>(
        <Box>
            <Typography sx={{fontSize:'18px', color:`${theme.palette.black}87`, fontWeight:'bold'}}>{title}</Typography>
            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}60`}}>{sub}</Typography>
        </Box>
     )

     const renderRowItem = (icon:string, text:string) => (
        <Box sx={{display:'flex', alignItems:'center'}}>
            <img src={`/assets/icons/${icon}`} alt={icon} width={24} height={24} />
            <Typography sx={{fontSize:'14px', color:`${theme.palette.black}60`}}>{text}</Typography>
        </Box>
     )
    return(
        <Box sx={{
            height:'66px',
            backgroundColor:`${'#919EAB'}10`,
            padding:`10px ${theme.spacing(3)}`,
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
        }}>

        <Box sx={{display:'flex', width:'60%', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:'40px'}}>
                {renderTitle('Request Summary','Arimag Construction  |  info@arimagconstruction.com')}
                {renderTitle('Appraisers','Chris Walker + 2 Collaborators')}
            </Box>
            <Box sx={{display:'flex', alignItems:'center', gap:'20px'}}>
                {renderRowItem('attach_money.png','USD 0.00')}
                {renderRowItem('list_numbered.png', '16 assets')}
            </Box>
        </Box>

        <Box>
            <APIconButton 
                isRounded
                whiteBackground
                icon={<BorderColorIcon />}
                text="Edit request"
                onClick={()=>{navigate('/edit-request/iJFho6HMcMI9kabz')}}
            />
        </Box>
        </Box>
    )

}

export default RequestInfo;