import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { appTheme } from './themes/theme';
import AppRoutes from './routes/Approutes';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import './App.css';

// Create a QueryClient instance
const queryClient = new QueryClient();

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={appTheme}>
          <CssBaseline enableColorScheme />
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <ToastContainer />
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
