import { Box, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { format } from "date-fns";
import { useState } from "react";
import ATPTableToolbar from "./APTableToolbar";
import APMediaCell from "./APMediaCell";
import APStatusCell from "./APStatusCell";
import APDefaultCell from "./APDefaultCell";
import APActionCell from "./APActionCell";
import APImageText from "./APImageText";
import APImageBadge from "./APImageBadge";
import { IColDef, ITableAction, ITableImageText } from "./tableInterfaces";
import { ITableFilter } from "../../../interfaces";
import { MaterialSymbol } from "react-material-symbols";
import { APTablePagination } from "./APTablePagination";
import APImageText2 from "./APImageText2";

interface Props {
  columns: IColDef[];
  data: any;
  isLoading?: boolean;
  hideActions?:boolean;
  showCheckbox?: boolean;
  showToolbar?: boolean;
  rowPerPage?: number;
  actions?: ITableAction[];
  clickableFields?: string[];
  onRowClick?: (row: any) => void;
  filterItems?: ITableFilter[];
  onRowSelection?: (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => void;
}

const APTable: React.FC<Props> = ({
  columns,
  data,
  isLoading,
  hideActions = false,
  showCheckbox,
  showToolbar,
  rowPerPage = 10,
  actions = [],
  clickableFields = [],
  onRowClick,
  filterItems = [],
  onRowSelection
}) => {
  const [menuState, setMenuState] = useState<{ [key: string]: { anchorEl: null | HTMLElement, open: boolean } }>({});

  const handleCloseMenu = (rowId: string) => {
    setMenuState(prevState => ({
      ...prevState,
      [rowId]: { anchorEl: null, open: false }
    }));
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
    setMenuState(prevState => ({
      ...prevState,
      [rowId]: { anchorEl: event.currentTarget, open: true }
    }));
  };

  const handleCellClicked = (row: any) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const cols: any = columns.map((column) => {
    const colData = {
      ...column,
      disableColumnMenu: true,
      enableNestedDataAccess: '.',
      flex: column.width ? null : 1
    };

    if (colData.type === 'media') {
      return {
        ...colData,
        renderCell: (params: GridRenderCellParams<any, string>) => <APMediaCell value={params.value} />
      };
    } else if (colData.type === 'date') {
      return {
        ...colData,
        valueFormatter: (value: string) => format(new Date(value), 'dd/M/yyyy | hh:mma'),
      };
    } else if (colData.type === 'status') {
      return {
        ...colData,
        renderCell: (params: GridRenderCellParams<any, string>) => <APStatusCell value={params.value} />,
      };
    } else if (colData.type === 'action') {
      return {
        ...colData,
        disableExport: true,
        disableReorder: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, ITableAction[]>) => {
          const isComplete = params.row.status === 'Completed';
          const rowId = params.row.id.toString();
          const menu = menuState[rowId] || { anchorEl: null, open: false };
          return (
            hideActions?
            <Box
            sx={{display:'flex', height:'100%', alignItems:'center'}}
            >
              <Box
                sx={{height:'24px', width:'85px', display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:(theme)=> `${theme.palette.black}10`,
                  opacity:0.5,
                  borderRadius: '24px',}}
              >
                <Typography sx={{fontSize:'14px'}}>Options</Typography>
                <MaterialSymbol icon="keyboard_arrow_down"/>
              </Box>
            </Box>:
            <APActionCell
              menu={menu}
              onOpenMenu={(event) => handleOpenMenu(event, rowId)}
              onCloseMenu={() => handleCloseMenu(rowId)}
              actions={actions}
              isComplete={isComplete}
              row={params.row}
            />
          );
        },
      };
    } else if (colData.type === 'imageText'||colData.type === 'imageText2') {
      return {
        ...colData,
        renderCell: (params: GridRenderCellParams<any, ITableImageText>) => {
          const label = params.row[params.field];
          return colData.type === 'imageText' ? <APImageText data={{ label, image: '' }} />:<APImageText2 data={{ label, image: '' }} />;
        },
      };
    } else if (colData.type === 'imageBadge') {
      return {
        ...colData,
        renderCell: (params: GridRenderCellParams<any, any>) => <APImageBadge data={params.value}/>,
      };
    } else {
      return {
        ...colData,
        renderCell: (params: GridRenderCellParams<any, string>) => (
          <APDefaultCell
            value={params.value}
            onClick={() => handleCellClicked({ field: params.field, data: params.row })}
            clickable={clickableFields.includes(params.field)}
          />
        ),
      };
    }
  });

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
         backgroundColor: '#EBF1FC',
      },
      '& .MuiDataGrid-row': {
        borderTop: 'none !important',
      },
      '& .MuiDataGrid-row--firstVisible': {
        borderTop: 'none',
      },
    }}>
      <DataGrid
        rows={data}
        columns={cols}
        slots={{
          toolbar: showToolbar ? ATPTableToolbar : null,
          pagination: APTablePagination
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: rowPerPage,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        checkboxSelection={showCheckbox}
        disableRowSelectionOnClick
        loading={isLoading}
        sx={{
          boxShadow: 0,
          border: 0,
          borderRadius: '8px',
          minHeight: '400px',
          height:'auto',
          maxHeight:'83vh'
        }}
        filterModel={{items:filterItems}}
        onRowSelectionModelChange={onRowSelection}
      />
    </Box>
  );
};

export default APTable;
