import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NotFoundPage = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
            }}
        >
            <ErrorOutlineIcon sx={{ fontSize: 100, color: '#ff6b6b' }} />
            <Typography variant="h1" component="div" sx={{ fontSize: 48, margin: '16px 0' }}>
                404
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Oops! The page you're looking for doesn't exist.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleGoHome}
                sx={{ textTransform: 'none', borderRadius: '20px', padding: '10px 20px' }}
            >
                Go to Home
            </Button>
        </Box>
    );
};

export default NotFoundPage;
