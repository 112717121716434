import { Box } from "@mui/material"
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TABLE_DATA } from "../../data/tableData";
import { useCallback, useEffect, useState } from "react";
import RequestTable from "./RequestTable";
import APTableFilter from "../../components/common/table/APTableFilter";
import { STATUS_FILTERS } from "../../Constant";

const userFilters: string[] = ['All', 'Assigned to me', 'Shared'];

const RequestsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<any[]>([]);

    const filter = location.state?.filter || 'All';

    useEffect(() => {
        applyFilter(filter);
    }, [filter]);

    const applyFilter = (filter: string) => {
        if (filter === 'All') {
            setData(TABLE_DATA);
        } else {
            const filteredData = TABLE_DATA.filter((item) => item.status === filter);
            setData(filteredData);
        }
    };

    const handleFilterClicked  = (filter:string) => {
        const filteredData = filter ==='All'?TABLE_DATA:TABLE_DATA.filter((item)=>item.status === filter);
        setData(filteredData);
    } 

    const handleOwnerClicked = (filter:string) => {

    }

    // const handleSearchResult = useCallback((results: any) => {
    //     if (results) {
    //         setData(results);
    //     } else if (results === undefined && prevResults && prevResults.length === 0) {
    //         refetch();
    //     }
        
    //     // Update the previous results
    //     setPrevResults(results);
    // }, [refetch, prevResults]);

    return(
        <Box sx={{padding:theme=> theme.spacing(3)}}>
            <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                marginBottom:'10px'
            }}>
                <APTableFilter
                    showAdd  
                    showFilters
                    showStatusFilter
                    showSearch
                    filterItems={userFilters}
                    statusFilterItems={STATUS_FILTERS}
                    addText="New request"
                    addAction={()=>{navigate('/new-request')}}
                    statusFilterAction={handleFilterClicked}
                    filterAction={handleOwnerClicked}
                />
            </Box>
            <Box sx={{height:370}}>
                <RequestTable isLoading={false} data={data} rowPerPage={10}/>
            </Box>
            <Outlet />
        </Box>
    )
}

export default RequestsPage;