import { Box } from "@mui/material";
import APTableFilter from "../../components/common/table/APTableFilter";
import { APIconButton } from "../../components/common/buttons";
import { MaterialSymbol } from "react-material-symbols";

const filters: string[] = ['All', 'Purple Wave', 'Third party 1'];
const AssetCompareTableActions = () => {
    return(
        <Box sx={{display:'flex', alignItems:'center', width:'100%',justifyContent:'end',gap:1, mb:'12px'}}>
            <APTableFilter
                showFilters
                showSearch
                showStatusFilter
                searchEndpoint="/api/v1/assets"
                filterItems={filters}
                // statusFilterItems={USER_STATUS_FILTERS}
                // statusFilterAction={onStatusChange}
                // addAction={handleNewUser}
                // onSearchResult={onSearchResult}
            />
            <Box>
                <APIconButton
                    text={'Report'}
                    onClick={()=>{}}
                    icon={
                      <Box sx={{ transform: 'rotate(-45deg)' }}>
                        <MaterialSymbol icon="send"/>
                      </Box>
                    }
                /></Box>
        </Box>
    )
}

export default AssetCompareTableActions;