import { Box } from "@mui/material";

interface Props {
    pwHeight?: number;
    pwasHeight?: number;
}
const AppLogo:React.FC<Props> = ({pwHeight, pwasHeight}) => {

    return (
        <Box>
            <img
              src={'/assets/images/purplewave-logo.png'}
              alt="Purplewave Logo"
              style={{ height: `${pwHeight??50}px` }}
            />
            <img
              src={'/assets/images/appraisal-service-logo.png'}
              alt="Appraisal Service Logo"
              style={{ height: `${pwasHeight??25}px`, marginBottom: 2 }}
            />
          </Box>
    )

}

export default AppLogo;