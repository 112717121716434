import { Box } from "@mui/material";
import APTableFilter from "../../components/common/table/APTableFilter";
import { APIconButton } from "../../components/common/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { USER_STATUS_FILTERS } from "../../Constant";
import OrganizationSelector from "../../components/common/OrganizationSelector";
import MenuActions from "./UserActionMenu";
import { useRef, useState } from "react";
const menus: string[] = ['Add individual','Add multiple','Get sample'];

interface TableActionsProps {
    selectedRowIds: string[];
    onOpenForm:()=>void;
    onStatusChange: (status: string) => void;
    onBulkDelete: () => void;
    onSearchResult: (results: any) => void;
}

const UserTableActions:React.FC<TableActionsProps> = ({selectedRowIds, onBulkDelete, onOpenForm, onStatusChange, onSearchResult}) => {
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

    const handleNewUser = (event:any) => setUserMenuAnchorEl(event.currentTarget);
    const handleUserMenuClose = (type?:string) => {
        setUserMenuAnchorEl(null);
        switch(type){
            case 'Add individual':
                onOpenForm();
                break;
            case 'Add multiple':
                fileInputRef.current?.click();
                break;
            case 'Get sample':
                downloadLinkRef.current?.click();
                break;
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log('File uploaded:', file);
            // Process the file with FileReader or another method here
        }
    };
    
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: '12px' }}>
            <OrganizationSelector />
            {selectedRowIds.length<2 && <APTableFilter
                showAdd
                showSearch
                showStatusFilter
                addText="New user"
                searchEndpoint="/api/v1/users"
                statusFilterItems={USER_STATUS_FILTERS}
                statusFilterAction={onStatusChange}
                addAction={handleNewUser}
                onSearchResult={onSearchResult}
            />}
            {
                (selectedRowIds.length===2 || selectedRowIds.length > 2) && 
                    <Box sx={{mb: '12px'}}>
                        <APIconButton 
                            text='Delete selected'
                            isRounded
                            color={'secondary'}
                            icon={<MaterialSymbol icon="delete" />}
                            onClick={onBulkDelete}
                        />
                    </Box>
            }
            <MenuActions menus={menus} anchorEl={userMenuAnchorEl} onMenuClicked={(val)=>handleUserMenuClose(val)}/>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
            />

            {/* Hidden anchor element for downloading the sample file */}
            <a
                href="/assets/docs/users_sample.xlsx"
                download="users_sample.xlsx"
                ref={downloadLinkRef}
                style={{ display: 'none' }}
            >
                Download Sample
            </a>
        </Box>
    );
};

export default UserTableActions;
