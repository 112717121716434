import React from 'react';
import { Box, Button} from '@mui/material';
import APDivider from '../../../components/common/APDivider';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ExcelFileInput, RichTextInput, SelectInput } from '../../../components/common/inputs';
import { EVALUATION_REASONS } from '../../../data/selectData';
import { USERS } from '../../../data/userData';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LabelInput from '../../../components/common/inputs/LabelInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isEmail, isRequired } from '../../../utils/validationRules';

interface FormValues {
  ownerName: string;
  ownerEmail: string;
  location: string;
  reasons:any;
  primaryAppraiser:any;
  collaborators:any;
  fileData:any;
}
const RequestForm = () => {
  const { control, handleSubmit,reset } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log('Form Data:', data);
    reset();
  };

  return (
    <Box sx={{ padding:theme=> theme.spacing(3)}}>
        <Box>
            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <APDivider text='Who owns this equipment?' />
                <Box sx={{
                  display:'flex',
                  width:'50%',
                  gap:3,
                  paddingTop:'15px'
                }}>
                  <LabelInput
                    id="ownerName"
                    label="Owner Name*"
                    name="ownerName"
                    control={control}
                    rules={{ 
                      validate: {
                        isRequired
                      } 
                    }}
                  />
                    <LabelInput
                      id="ownerEmail"
                      label="Owner email*"
                      name="ownerEmail"
                      control={control}
                      rules={{ 
                        validate: {
                          isRequired,
                          isEmail
                        } 
                      }}
                      />
                </Box>
              </Box>

              <Box sx={{marginTop:'38px'}}>
                <APDivider text='Where is this equipment?' />
                <Box sx={{
                  display:'flex',
                  width:'24.5%',
                  paddingTop:'15px'
                }}>

                  <LabelInput
                    id="location"
                    label="Location*"
                    name="location"
                    control={control}
                    rules={{ 
                      validate: {
                        isRequired
                      } 
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{marginTop:'38px'}}>
                <APDivider text='More information' />
                <Box sx={{
                  display:'flex',
                  width:'24.5%',
                  paddingTop:'15px'
                }}>
                  <SelectInput 
                    id='reasons'
                    label='Select evaluation reasons'
                    name='reasons'
                    options={EVALUATION_REASONS}
                    isMultiple
                    control={control}
                  />
                </Box>
              </Box>
              <Box sx={{marginTop:'38px'}}>
                <APDivider text='Notes' />
                <Box sx={{
                  width:'100%',
                  paddingTop:'15px'
                }}>
                  <RichTextInput 
                    id='notes'
                    name='notes'
                    control={control}
                  />
                </Box>
              </Box>

              <Box sx={{marginTop:'38px'}}>
                <APDivider text='Who is managing this request?' />
                <Box sx={{
                  width:'24.5%',
                  paddingTop:'15px'
                }}>
                  <SelectInput 
                    id='primaryAppraiser'
                    label='Primary appraiser*'
                    name='primaryAppraiser'
                    options={USERS}
                    control={control}
                    rules={{validate:{isRequired}}}
                  />
                  <SelectInput 
                    id='collaborators'
                    label='Collaborators'
                    name='collaborators'
                    options={USERS}
                    control={control}
                    isMultiple
                    // rules={{validate:{isRequired}}}
                  />
                </Box>
              </Box>
              <Box sx={{marginTop:'38px'}}>
                <APDivider text='Add assets' />
                <ExcelFileInput
                  id="fileData"
                  label="Drag and drop or choose an Excel file"
                  name="fileData"
                  control={control}
                  width='25%'
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Box>
                  <Button type="submit" variant="contained" color="primary">
                    Create request
                  </Button>
                  <Button variant="outlined" sx={{ml:1}}>Cancel</Button>
                </Box>

                <Button variant="text" color="primary" endIcon={<ArrowForwardIosIcon />}>
                  Save and create another
                </Button>
              </Box>
            </ValidatorForm>
        </Box>
    </Box>
  );
};

export default RequestForm;
