import * as React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller } from 'react-hook-form';

interface StyledTextValidatorProps {
  background?: 'filled' | 'transparent';
  size?: string;
}

const StyledTextValidator = styled(TextField)<StyledTextValidatorProps>(({ background, size }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: background === 'transparent' ? '#fff' : '#f0f0f0',
    borderRadius: background === 'transparent'?null:'8px',
    border: background === 'transparent' ? '1px solid #f0f0f0' : 'none',
    height: size === 'small' ? '42px':null
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',             // Remove the border
  },
}));

interface LabeledInputProps {
  id?: string;
  label: string;
  name: string;
  control: Control<any>;
  defaultValue?: string;
  size?: 'small'|'medium';
  background?: 'filled' | 'transparent';
  rules?: object;
}

const LabelInput: React.FC<LabeledInputProps> = ({
  id, label, name, size = 'small', background = 'transparent', control, defaultValue='', rules
}) => {
  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize:'12px', color:'#4F4F4F' }}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
            <StyledTextValidator
            {...field}
            id={id}
            variant="outlined"
            size={size}
            fullWidth
            background={background}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ''}
          />
        )}
      />
    </Box>
  );
};

export default LabelInput;
