import Button from "@mui/material/Button";
import { appTheme } from "../themes/theme";

const DesignSystemPage = () => {
    return (
        <>
        <div style={{ margin: "1em" }}>
        <Button color="primary" variant="contained">
          Primary
        </Button>
        <Button color="secondary" variant="contained">
          Secondary
        </Button>
        <Button color="success" variant="contained">
          Success
        </Button>
        <Button color="error" variant="contained">
          Error
        </Button>
        <Button color="warning" variant="contained">
          Warning
        </Button>
        <Button sx={{backgroundColor: appTheme.palette.cerulean_blue}} variant="contained">
          Cerulean Blue
        </Button>
        <Button sx={{backgroundColor: appTheme.palette.purple_illusionist}} variant="contained">
          Purple Illusionist
        </Button>
      </div>
        </>
    )
}

export default DesignSystemPage;