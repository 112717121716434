// theme.ts
import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    primary: {
      light: '#72519B',
      main: '#4f2682',
      dark: '#371A5B',
    },
    secondary: {
      light: '#F73378',
      main: '#D32F2F',
      dark: '#AB003C',
    },
    text: {
      primary: '#19161D',
    },
    cerulean_blue: '#2E61B4',
    purple_illusionist: '#A56EFF',
    divider_color: 'rgba(0, 0, 0, 0.06)',
    black: '#000000',
    white: '#ffffff',
    new_color:  '#F2F2F2',
    in_progress_color: '#2E61B4',
    awaiting_feedback_color:  '#A56EFF',
    completed_color:  '#3E875E'
  },
  typography:{
    button: {
      textTransform: 'none'
    }
  }
});
