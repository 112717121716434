import { Button } from "@mui/material";
import { MaterialSymbol } from "react-material-symbols";

interface Props{
    onChange?:(file:File)=>void;
    handleFileChange?: (file:File)=>void;
    onBlur?: any;
    accepts?:string;
    isCircle?:boolean;
}
const APFileButton:React.FC<Props> = ({onChange, handleFileChange, isCircle, onBlur, accepts}) => {
    return (
        <Button
            variant="contained"
            component="label"
            endIcon={<MaterialSymbol
                icon={'cloud_upload'}
                size={isCircle?18:24}
            />}
            sx={{ 
                marginTop: '10px', 
                borderRadius: '40px',
                height:isCircle?'30px':'42px',
                fontSize:isCircle?'10px':'14px'
            }}
        >
            Choose file
            <input
                type="file"
                accept={accepts}
                hidden
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                        if (onChange) onChange(file);
                        if (handleFileChange) handleFileChange(file);
                    }
                }}
                onBlur={onBlur}
            />
        </Button>
    )
}

export default APFileButton;