import { Box, Typography, IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import { ITableAction } from "./tableInterfaces";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
  onOpenMenu?: (event: any) => void;
  onCloseMenu?: () => void;
  actions?: ITableAction[];
  isComplete?: boolean;
  row?: any;
  menu?: any;
}

const APActionCell: React.FC<Props> = ({ onCloseMenu, onOpenMenu, actions = [], isComplete, row, menu }) => {
  const theme = useTheme();

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '24px',
        backgroundColor: `${theme.palette.black}10`,
        height: '24px',
        width: '85px'
      }}>
        <Typography sx={{ fontSize: '14px' }}>Options</Typography>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          sx={{
            fontSize: '16px',
            padding: 0
          }}
          onClick={onOpenMenu}
        >
          {menu.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={menu.anchorEl}
        keepMounted
        open={menu.open}
        onClose={onCloseMenu}
        sx={{
          padding: '0px 20px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: '251px',
              borderRadius: '16px',
              padding: '8px',
              boxShadow: `2px 2px 4px ${theme.palette.black}10`
            }
          }
        }}
      >
        {actions.map((action, index) => {
          let isDisabled = false;
          let label = action.label;

          if (row?.status) {
            const status = row.status.toLowerCase();

            if (status === 'completed') {
              if (label.toLowerCase().includes('edit request') || label.toLowerCase().includes('delete')) {
                isDisabled = true;
              }
            } else if (label.toLowerCase().includes('report')) {
              isDisabled = true;
            }

            if (status === 'active') {
              if (label.toLowerCase().includes('resend invite') || label.toLowerCase().includes('reset password')) {
                isDisabled = true;
              }
              if (label.toLowerCase().includes('block/unblock user')) {
                label = 'Block user';
              }
            }

            if (status === 'blocked') {
              if (label.toLowerCase().includes('resend invite') || label.toLowerCase().includes('reset password')) {
                isDisabled = true;
              }
              if (label.toLowerCase().includes('block/unblock user')) {
                label = 'Unblock user';
              }
            }

            if (status === 'pending') {
              if (label.toLowerCase().includes('block') || label.toLowerCase().includes('reset password')) {
                isDisabled = true;
              }
            }

            if (status === 'locked') {
              if (label.toLowerCase().includes('resend invite') || label.toLowerCase().includes('block')) {
                isDisabled = true;
              }
            }
          }

          return (
            <MenuItem
              key={index}
              sx={{
                backgroundColor: action.label.includes('TRASH') ? `${theme.palette.error.main}20` : null,
                borderRadius: action.label.includes('TRASH') ? '8px' : null,
                pointerEvents: isDisabled ? 'none' : 'auto',
                opacity: isDisabled ? 0.5 : 1,
                marginBottom: index < actions.length - 1 ? '4px' : '0',
                fontSize:'14px'
              }}
              onClick={() => {
                if (!isDisabled) {
                  action.action(row);
                  onCloseMenu?.();
                }
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default APActionCell;
