import { Box, Modal, Typography, Grid } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { ValidatorForm } from "react-material-ui-form-validator";
import { IUserForm } from "./userInterfaces";
import { useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { postUser, updateUser } from "./userQueries";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";
import { ROLES } from "../../Constant";
import { APIconButton } from "../../components/common/buttons";
import { MaterialSymbol } from "react-material-symbols";
import { LabelInput, SelectInput } from "../../components/common/inputs";
import { isEmail, isRequired } from "../../utils/validationRules";

interface Props {
  open: boolean;
  onClose: (event: any) => void;
  selectedRow?: any;
  refetchUsers?: () => void;
}

const UserForm: React.FC<Props> = ({ open, onClose, selectedRow, refetchUsers }) => {
  const { control, handleSubmit, reset } = useForm<IUserForm>();
  const userRole = useMemo(() => {
    return selectedRow 
      ? ROLES.find((role) => role.value === selectedRow.role)
      : null;
  }, [selectedRow]);

  const handleOnClose = (event: any) => {
    reset({
      name: '',
      email: '',
      role: null,
    });
    onClose(event);
  };

  const { isPending: isPosting, mutate: postUserMutation } = useMutation({
    mutationKey: ['post-user'],
    mutationFn: postUser,
    onSuccess: (response) => {
      toast.success('Invite sent successfully');
      refetchUsers?.();
      handleOnClose(null);
    },
    onError: handleError,
  });

  const { isPending: isUpdating, mutate: updateUserMutation } = useMutation({
    mutationKey: ['update-user'],
    mutationFn: ({ userId, formValues }: { userId: string, formValues: IUserForm }) =>
      updateUser(userId, formValues),
    onSuccess: () => {
      toast.success('User updated successfully');
      refetchUsers?.();
      handleOnClose(null);
    },
    onError: handleError,
  });

  const onSubmit: SubmitHandler<IUserForm> = (data) => {
    const payload = { ...data, role: data.role.id };
    if (selectedRow) {
      updateUserMutation({ userId: selectedRow.id, formValues: payload });
    } else {
      postUserMutation(payload);
    }
  };

  useEffect(() => {
    if (selectedRow) {
      reset({
        name: selectedRow.name,
        email: selectedRow.email,
        role: userRole,
      });
    } else {
      reset({
        name: '',
        email: '',
        role: null,
      });
    }
  }, [selectedRow, reset, userRole]);

  const modalTitle = selectedRow ? 'Edit user' : 'Add user';
  const buttonText = selectedRow ? 'Update' : 'Send request';

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#FCFCFC',
                p: '25px',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>
                {modalTitle}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box>
                  <APIconButton
                    text="Cancel"
                    icon={<MaterialSymbol icon="close" />}
                    onClick={handleOnClose}
                    whiteBackground
                  />
                </Box>
                <APIconButton
                  text={buttonText}
                  icon={<MaterialSymbol icon="add" />}
                  type="submit"
                  isLoading={isPosting || isUpdating}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', p: '25px' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LabelInput
                    id="user-fullname"
                    label="Enter full name"
                    name="name"
                    control={control}
                    rules={{ validate: { isRequired } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabelInput
                    id="user-email"
                    label="Enter email"
                    name="email"
                    control={control}
                    rules={{ validate: { isRequired, isEmail } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    id="role"
                    label="Select role"
                    name="role"
                    options={ROLES}
                    control={control}
                    rules={{ validate: { isRequired } }}
                  />
                </Grid>
              </Grid>
            </Box>
          </ValidatorForm>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserForm;
