import { useEffect, useState } from 'react';
import { Box, Button, Typography} from '@mui/material';
import APDivider from '../../../components/common/APDivider';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { ExcelFileInput, RichTextInput, SelectInput } from '../../../components/common/inputs';
import { EVALUATION_REASONS } from '../../../data/selectData';
import { USERS } from '../../../data/userData';
import LabelInput from '../../../components/common/inputs/LabelInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isEmail, isRequired } from '../../../utils/validationRules';
import { DUMMY_REQUEST_DATA } from '../../../data/dummyEditRequestData';
import { APIconButton } from '../../../components/common/buttons';
import { Delete } from '@mui/icons-material';

interface FormValues {
  ownerName: string;
  ownerEmail: string;
  location: string;
  reasons:any;
  primaryAppraiser:any;
  collaborators:any;
  fileData:any;
  notes:string;
}
const RequestEditForm = () => {
  const { control, handleSubmit,reset, getValues } = useForm<FormValues>();
  const [tableData, setTableData] = useState([]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log('Form Data:', data);
    reset();
  };

  useEffect(()=>{
    reset(DUMMY_REQUEST_DATA);
    setTableData(getValues('fileData')['tableData']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Box sx={{ display:'flex', alignItems:'start', gap:'25px', width:'100%', height:'95vh', overflow:'auto',padding:theme=> theme.spacing(3)}}>
        <Box sx={{width:'80%', height:'100%'}}>
            <ValidatorForm onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <APDivider text='Who owns this equipment?' />
                <Box sx={{
                  display:'flex',
                  width:'50%',
                  gap:3,
                  paddingTop:'15px'
                }}>
                  <LabelInput
                    id="ownerName"
                    label="Owner Name*"
                    name="ownerName"
                    control={control}
                    rules={{ 
                      validate: {
                        isRequired
                      } 
                    }}
                  />
                    <LabelInput
                      id="ownerEmail"
                      label="Owner email*"
                      name="ownerEmail"
                      control={control}
                      rules={{ 
                        validate: {
                          isRequired,
                          isEmail
                        } 
                      }}
                      />
                </Box>
              </Box>

              <Box sx={{marginTop:'8px'}}>
                <APDivider text='Where is this equipment?' />
                <Box sx={{
                  display:'flex',
                  width:'24.5%',
                  paddingTop:'15px'
                }}>

                  <LabelInput
                    id="location"
                    label="Location*"
                    name="location"
                    control={control}
                    rules={{ 
                      validate: {
                        isRequired
                      } 
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{marginTop:'8px'}}>
                <APDivider text='More information' />
                <Box sx={{
                  display:'flex',
                  width:'24.5%',
                  paddingTop:'15px'
                }}>
                  <SelectInput 
                    id='reasons'
                    label='Select evaluation reasons'
                    name='reasons'
                    options={EVALUATION_REASONS}
                    isMultiple
                    control={control}
                  />
                </Box>
              </Box>
              <Box sx={{marginTop:'8px'}}>
                <APDivider text='Notes' />
                <Box sx={{
                  width:'100%',
                  paddingTop:'15px'
                }}>
                  <RichTextInput 
                    id='notes'
                    name='notes'
                    control={control}
                  />
                </Box>
              </Box>

              <Box sx={{marginTop:'8px'}}>
                <APDivider text='Who is managing this request?' />
                <Box sx={{
                  width:'24.5%',
                  paddingTop:'15px'
                }}>
                  <SelectInput 
                    id='primaryAppraiser'
                    label='Primary appraiser*'
                    name='primaryAppraiser'
                    options={USERS}
                    control={control}
                    rules={{validate:{isRequired}}}
                  />
                  <SelectInput 
                    id='collaborators'
                    label='Collaborators'
                    name='collaborators'
                    options={USERS}
                    control={control}
                    isMultiple
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <Box>

                  <Button type="submit" variant="contained" color="primary">
                    Save update
                  </Button>
                  <Button variant="outlined" sx={{ml:1}}>Cancel</Button>
                </Box>

                <Box>
                  <APIconButton 
                    text='Delete Request'
                    isRounded={false}
                    color={'secondary'}
                    icon={<Delete />}
                    onClick={()=>{}}
                  />
                </Box>
              </Box>
            </ValidatorForm>
        </Box>
        <Box sx={{width:'20%', height:'100%'}}>
          <Box sx={{marginTop:'12px', marginBottom:'20px'}}>
            <ExcelFileInput
              id="fileData"
              label="Drag and drop or choose an Excel file"
              name="fileData"
              control={control}
            />
          </Box>
          <Box sx={{display:'flex', flexDirection:'column', alignItems:'end', height:'86.3%'}}>
            <Typography sx={{fontSize:'12px', color:(theme)=>`${theme.palette.black}80`}}>Uploaded {tableData.length} assets</Typography>
            <Box sx={{width:'100%', overflow:'auto', display:'flex', flexWrap:'wrap', justifyContent:'space-between'}}>
              {tableData.map((data,index)=>(
                <Box key={index} sx={{width:'49%'}}>
                  <img src='/assets/images/placeholder.png' alt='placeholder' width={'100%'} height={150}/>
                </Box>
              ))}
            </Box>
          </Box>

        </Box>
    </Box>
  );
};

export default RequestEditForm;
