import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useAuthStore } from '../../../features/auth/authStore';

interface WarningModalProps {
  open: boolean;
  onClose: () => void;
  onStayLoggedIn: () => void;
}

const LogoutWarningModal: React.FC<WarningModalProps> = ({ open, onClose, onStayLoggedIn }) => {
  const [timeLeft, setTimeLeft] = useState<number>(300); // 5 minutes = 300 seconds
  const [timerActive, setTimerActive] = useState<boolean>(false); // To control timer activation
  const logout = useAuthStore.getState().clearLogin;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (open) {
      setTimerActive(true); // Start the timer when the modal opens
    }

    if (timerActive && timeLeft > 0) {
      timeoutId = setTimeout(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000); // Update every second
    } else if (timeLeft <= 0) {
      onClose(); // Automatically close the modal when time runs out
    }

    return () => clearTimeout(timeoutId); // Clean up the timeout on unmount or when timeLeft changes
  }, [open, timeLeft, timerActive, onClose]);

  const handleStayLoggedIn = () => {
    setTimeLeft(300); // Reset the timeLeft to 5 minutes
    setTimerActive(true); // Restart the timer
    onStayLoggedIn(); // Execute the stay logged-in function
  };

  const handleLogout = () => {
    logout();
  }

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Inactivity Warning
        </Typography>
        <Typography sx={{ mt: 2 }}>
          You will be logged out in {formatTime(timeLeft)} due to inactivity.
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" onClick={handleStayLoggedIn}>
            Stay Logged In
          </Button>
          <Button variant="outlined" onClick={handleLogout}>
            Logout Now
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutWarningModal;
