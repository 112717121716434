import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface Props{
    children?: ReactNode;
}
const CustomDrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent:'space-between'
  }));

const DrawerHeder: React.FC<Props> = ({children}) => (
  <CustomDrawerHeader>{children}</CustomDrawerHeader>
);

export default DrawerHeder;