import { Box, Typography } from "@mui/material";
import { ITableImageText } from "./tableInterfaces";

interface Props{
    data?: ITableImageText;
}
const APImageText2:React.FC<Props> = ({data}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', height:'100%', gap: 1 }}>
            <Box sx={{width:'50px',height:'40px', backgroundColor:'#D9D9D9'}}>
                {data?.image && <img src={data?.image} alt={data?.label} width={'100%'} height={'100%'}/>}
            </Box>
            <Typography>{data?.label}</Typography>
        </Box>
    )
}

export default APImageText2;