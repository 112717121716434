import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig{
    applyAuth?: boolean;
}

export const appAPI: AxiosInstance = axios.create({baseURL:process.env.REACT_APP_BASE_URL});
appAPI.interceptors.request.use(
    (config:any)=>{
        const token = localStorage.getItem('token');
        if(token && config.applyAuth !== false){
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error)=>{
        return Promise.reject(error);
    }
);