// src/utils/validators.ts

import { ValidatorForm } from 'react-material-ui-form-validator';

// Function to add custom validation rules
export const addCustomValidationRules = (formData?:any) => {
  ValidatorForm.addValidationRule('isEmail', (value) => {
    // Simple email regex validation
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
  });
  ValidatorForm.addValidationRule('isUpperCase', (value) => {
    return /[A-Z]/.test(value);
  });
  ValidatorForm.addValidationRule('isLowerCase', (value) => {
    return /[a-z]/.test(value);
  });
  ValidatorForm.addValidationRule('hasNumber', (value) => {
    return /\d/.test(value);
  });
  ValidatorForm.addValidationRule('hasSpecialChar', (value) => {
    return /[@$!%*?&]/.test(value);
  });
  ValidatorForm.addValidationRule('isMinLength', (value) => {
    return value.length >= 12;
  });
  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    return value === formData.password;
  });
};

// Function to remove custom validation rules
export const removeCustomValidationRules = () => {
  ValidatorForm.removeValidationRule('isEmail');
  ValidatorForm.removeValidationRule('isUpperCase');
  ValidatorForm.removeValidationRule('isLowerCase');
  ValidatorForm.removeValidationRule('hasNumber');
  ValidatorForm.removeValidationRule('hasSpecialChar');
  ValidatorForm.removeValidationRule('isMinLength');
  ValidatorForm.removeValidationRule('isPasswordMatch');
};
