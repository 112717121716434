import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { DesignSystemPage, NotFoundPage } from '../pages';
import AuthLayout from '../features/auth/AuthLayout';
import ForgotPasswordForm from '../features/auth/forgotPasswordForm';
import LoginForm from '../features/auth/loginForm';
import ResetPasswordForm from '../features/auth/resetPasswordForm';
import OTPForm from '../features/auth/otpForm';
import AppLayout from '../pages/AppLayout';
import DashboardPage from '../features/dashboard/DashboardPage';
import RequestsPage from '../features/requests/RequestsPage';
import RequestForm from '../features/requests/request-form/RequestForm';
import RequestEditForm from '../features/requests/request-form/EditRequestForm';
import AssetsPage from '../features/assets/AssetsPage';
import ImageUploader from '../dev/BulkFileUploadPoc';
import UserManagementPage from '../features/user-management/UserManagementPage';
import OrganizationManagementPage from '../features/organization-manageent/OrganizationManagementPage';
import AssetComparePage from '../features/asset-compare/AssetComparePage';
import NotAuthorizedPage from '../pages/NotAuthorizedPage';

const AppRoutes: React.FC = () => {
  const authToken = localStorage.getItem('authToken');

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/login" element={authToken ? <Navigate to="/" /> : <LoginForm />} />
        <Route path='/forgot-password' element={<ForgotPasswordForm />} />
        <Route path='/reset-password/*' element={<ResetPasswordForm />} />
        <Route path='/set-password/*' element={<ResetPasswordForm />} />
        <Route path='/confirm-email' element={<OTPForm />} />
      </Route>
      
      {/* Protected Routes */}
      <Route element={<ProtectedRoute allowedRoles={['super_admin', 'admin', 'user']} />}>
        <Route element={<AppLayout />}>
          <Route path='/' element={<DashboardPage />} />
          <Route path='/requests' element={<RequestsPage />} />
          <Route path='/assets' element={<AssetsPage />} />
          <Route path='/compare/:id' element={<AssetComparePage />} />
          <Route path='/new-request' element={<RequestForm />} />
          <Route path='/edit-request/:id' element={<RequestEditForm />} />
          
        </Route>
      </Route>

      <Route element={<ProtectedRoute allowedRoles={['super_admin', 'admin']} />}>
        <Route element={<AppLayout />}>
          <Route path='/user-management' element={
            <UserManagementPage />
          } />
        </Route>
        </Route>

        <Route element={<ProtectedRoute allowedRoles={['super_admin']} />}>
        <Route element={<AppLayout />}>
          <Route path='/organization-management' element={
              <OrganizationManagementPage />
          } />
        </Route>
        </Route>

      {/* Developer Routes */}
      <Route element={<ProtectedRoute allowedRoles={['super_admin']} />}>
        <Route path="/dev/design-system" element={<DesignSystemPage />} />
        <Route path="/dev/file-upload-poc" element={<ImageUploader />} />
      </Route>

      {/* Not Authorized Route */}
      <Route path="/not-authorized" element={<NotAuthorizedPage />} />
      
      {/* Catch-all Route */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
