import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { SearchInput } from './inputs';
import { appAPI } from '../../httpClient';

interface DebouncedSearchProps {
    name?: string;
    apiEndpoint?: string; // The API endpoint to query
    debounceDelay?: number; // Delay in milliseconds
    onResultsFetched?: (results: any) => void; // Optional callback when results are fetched
}

const fetchSearchResults = async (apiEndpoint: string, query: string) => {
    const response = await appAPI.get(apiEndpoint, {
        params: { search: query }
    });
    return response.data;
};

const fetchDefaultResults = async (apiEndpoint: string) => {
    const response = await appAPI.get(apiEndpoint, {
        params: { search: '' }
    });
    return response.data;
};

const DebouncedSearch: React.FC<DebouncedSearchProps> = ({
    name,
    apiEndpoint,
    debounceDelay = 300,
    onResultsFetched,
}) => {
    const [searchQuery, setSearchQuery] = useState('');

    const debouncedQuery = useCallback(
        debounce((query: string) => {
            setSearchQuery(query);
        }, debounceDelay),
        [debounceDelay]
    );

    const { data: results, isFetching } = useQuery(
        {
            queryKey: ['searchResults', searchQuery],
            queryFn: () => fetchSearchResults(apiEndpoint!, searchQuery),
            enabled: searchQuery.length > 0 // Ensure the query is not empty
        }
    );

    const { data: defaultResults } = useQuery(
        {
            queryKey: ['defaultResults'],
            queryFn: () => fetchDefaultResults(apiEndpoint!),
            enabled: searchQuery.length === 0 // Fetch default results if query is empty
        }
    );

    useEffect(() => {
        if (onResultsFetched && !isFetching) {
            onResultsFetched(searchQuery.length > 0 ? results : defaultResults);
        }
    }, [results, defaultResults, isFetching, onResultsFetched, searchQuery]);

    useEffect(() => {
        return () => {
            debouncedQuery.cancel();
        };
    }, [debouncedQuery]);

    return (
        <Box>
            <SearchInput
                name={name}
                onChange={(event) => debouncedQuery(event.target.value)}
                isLoading={isFetching}
            />
        </Box>
    );
};

export default DebouncedSearch;
