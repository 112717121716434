type FlattenedObject = { [key: string]: any };

export function flattenObject(obj: any, prefix = ''): FlattenedObject {
    return Object.keys(obj).reduce((acc: FlattenedObject, k: string): FlattenedObject => {
      const pre = prefix.length ? `${prefix}.${k}` : k;
      if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
        Object.assign(acc, flattenObject(obj[k], pre));
      } else {
        acc[pre] = obj[k];
      }
      return acc;
    }, {});
  }