// src/components/OTPInput.tsx

import * as React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface OTPInputProps {
  length: number;
  onChange: (otp: string) => void;
  label: string;
}

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: '#f0f0f0', // Grey background
    borderRadius: '8px',        // Rounded corners
    textAlign: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',             // Remove the border
  },
  '& input': {
    textAlign: 'center',
    padding: '12px',
  },
});

const OTPInput: React.FC<OTPInputProps> = ({ length, onChange, label }) => {
  const [otp, setOtp] = React.useState<string[]>(new Array(length).fill(''));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;
    if (/^\d$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      onChange(newOtp.join(''));
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      (document.getElementById(`otp-${index - 1}`) as HTMLInputElement).focus();
    } else if (/^\d$/.test(event.key) && index < length - 1) {
      (document.getElementById(`otp-${index + 1}`) as HTMLInputElement).focus();
    }
  };

  return (
    <Box>
      <Typography component="label" variant="body1" sx={{ mb: 1, display: 'block' }}>
        {label}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {otp.map((digit, index) => (
          <StyledTextField
            key={index}
            id={`otp-${index}`}
            value={digit}
            onChange={(e:any) => handleChange(e, index)}
            onKeyUp={(e:any) => handleKeyUp(e, index)}
            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
            variant="outlined"
            placeholder="0"
            sx={{ width: '3rem' }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default OTPInput;
