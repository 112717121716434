import { Box } from "@mui/material";
import AppLogo from "../AppLogo";
import { motion } from "framer-motion";

const APLoader = () => {
    return (
        <Box
            sx={{
                position: "fixed",
                top: 65,
                left: 300,
                right: 0,
                bottom: 0,
                zIndex: 1300,
                backdropFilter: "blur(8px)",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                transition: "opacity 0.3s ease-in-out",
            }}
        >
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100%',width:'100%'}}>
                <motion.div
                    className="pulse-image"
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop', ease: 'easeInOut' }}
                >
                    <AppLogo pwHeight={100} pwasHeight={50}/>
                </motion.div>
            </Box>
        </Box>
    )
}

export default APLoader;