export const USERS = [
    {
        "id": "01J5C5BGDRBYSFNY2SKESEQA1R",
        "name": "Jon Su-Doe",
        "email": "john.su.doe@appraisal.com",
        "password_digest": "$2a$12$hVxOZIkiGi2H9zjsy0a13exkAXhYU.Q0yLRR2H22baKa.pp.ykEqW",
        "active": true,
        "role": "super_admin",
        "created_at": "2024-08-15T23:18:20.849Z",
        "updated_at": "2024-08-15T23:18:20.849Z",
        "locked_out": false,
        "user_role": null
    },
    {
        "id": "01J5DT9S6JHF5VJ193YFMFEXR4",
        "name": "Simon Bankole",
        "email": "simon.bankole@devopsafricalimited.com",
        "password_digest": "$2a$12$MYl4wbVsKoBEUUi.X.27/ufV.9SxOi6vbxZJkk.nh4989gBHmiw4G",
        "active": true,
        "role": "super_admin",
        "created_at": "2024-08-16T14:43:38.832Z",
        "updated_at": "2024-08-16T14:43:38.832Z",
        "locked_out": false,
        "user_role": null
    },
    {
        "id": "01J5DTW3XARVW337JXHNN1GTDC",
        "name": "Derrick Owusu Ansah",
        "email": "derrick.owusuansah@purplewave.com",
        "password_digest": "$2a$12$/mT6Iv9g69BOs03uVTQKH.S42SIMzwxL3phi5I1mA1bEGEkFr50Xa",
        "active": true,
        "role": "super_admin",
        "created_at": "2024-08-16T14:53:39.621Z",
        "updated_at": "2024-08-21T14:06:37.983Z",
        "locked_out": false,
        "user_role": null
    },
    {
        "id": "01J5DWSTPNQY9T27TC5CFZPD1K",
        "name": "Kofi Asante",
        "email": "derrick.owusuansah@devopsafricalimited.com",
        "password_digest": "$2a$12$eS7CzGq8IzOfHcya6JDLiOdJT9Govfm/9sxuiejZfj2CsXpQskL9e",
        "active": true,
        "role": "appraiser",
        "created_at": "2024-08-16T15:27:21.812Z",
        "updated_at": "2024-08-21T15:26:06.058Z",
        "locked_out": false,
        "user_role": null
    }
];