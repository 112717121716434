import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import { styled } from '@mui/system';

interface StyledTextValidatorProps {
  background?: 'filled' | 'transparent';
  size?: string;
}

const StyledTextValidator = styled(TextValidator)<StyledTextValidatorProps>(({ background, size }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: background === 'transparent' ? '#fff' : '#f0f0f0',
    borderRadius: background === 'transparent'?null:'8px',
    border: background === 'transparent' ? '1px solid #f0f0f0' : 'none',
    height: size === 'small' ? '42px':null
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',             // Remove the border
  },
}));

interface LabeledInputProps {
  id?: string;
  label: string;
  type?: string;
  name: string;
  value: string;
  size?: string;
  background?: 'filled' | 'transparent';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validators?: string[];
  errorMessages?: string[];
  [x: string]: any;
}

const LabelInput2: React.FC<LabeledInputProps> = ({
  id, label, type = 'text', name, value, size, background = 'filled', onChange, validators, errorMessages, ...props
}) => {
  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      <Typography component="label" htmlFor={id} variant="body1" sx={{ mb: 1, fontSize:'12px', color:'#4F4F4F' }}>
        {label}
      </Typography>
      <StyledTextValidator
        id={id}
        variant="outlined"
        size={size}
        fullWidth
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        validators={validators}
        errorMessages={errorMessages}
        background={background}
        {...props}
      />
    </Box>
  );
};

export default LabelInput2;
