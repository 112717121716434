import { Menu, MenuItem } from "@mui/material";

interface Props {
    menus: string[];
    anchorEl: null | HTMLElement;
    onMenuClicked: (menu?:string)=>void;
}

const MenuActions:React.FC<Props> = ({ menus, anchorEl, onMenuClicked }) => {
    return (
        <>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={()=>{}}>
                {
                    menus.map((menu)=>
                        <MenuItem key={menu} onClick={() => onMenuClicked(menu)}>{menu}</MenuItem>
                    )
                }
            </Menu>
        </>
    );
};

export default MenuActions;
