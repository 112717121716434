import { IColDef } from "../../components/common/table/tableInterfaces";
import APTable from "../../components/common/table/APTable";
import { flattenObject } from "../../utils/jsonObjectNormalize";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deleteOrganization } from "./organizationQueries";
import { useState } from "react";
import DeleteConfirmationDialog from "../../components/common/dialogs/DeleteConfirmationDialog";
import { toast } from "react-toastify";
import { handleError } from "../../utils/errorHandler";

const colDef: IColDef[] = [
    {
      field: 'name',
      headerName: 'Organizations',
      type: 'imageText',
    },
    {
      field: 'owner.name',
      headerName: 'Admin',
      type: 'imageText',
    },
    {
      field: 'owner.email',
      headerName: 'Email',
      width: 350,
    },
    {
      field: 'users',
      headerName: 'No. of users',
    },
    {
      field: 'created_at',
      headerName: 'Date added',
      type: 'date',
    },
    {
      field: 'actions',
      headerName: 'Action',
      type: 'action',
    },
  ];

interface Props{
    isLoading?:boolean;
    data?:any[];
    onRefetch?: ()=>void;
    onRowCheck?:(item:any,details:any)=>void;
    onOpenForm?:(row:any) => void;
}
const OrganizationTable:React.FC<Props> = ({data=[],isLoading, onRefetch, onRowCheck, onOpenForm}) => {
    const tableData = data.map((item:any) => flattenObject(item));
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openDeleteDialog, setOpenDeleteDialog]  = useState(false);
    const { isPending: isOrgDeleting, mutate: deleteOrgMution } = useMutation({
        mutationKey: ['delete-org'],
        mutationFn: (orgId: string) => deleteOrganization(orgId),
    });

    const actions = [
        {
          label: 'Edit details',
          action: (row:any)=>{
            if(onOpenForm)onOpenForm(row);
          }
        },
        {
          label: 'MOVE TO TRASH',
          action:(row:any)=>{
            setSelectedRow(row);
            setOpenDeleteDialog(true);
          }
        }
      ];

      const handleCancelDelete = () => {
        setSelectedRow(null);
        setOpenDeleteDialog(false);
    };

    const handleOnDelete = () => {
        if(!selectedRow) return;

        deleteOrgMution(selectedRow.id, {
            onSuccess: () => {
                toast.success('Successfully moved to trash');
                onRefetch?.();
                setOpenDeleteDialog(false);
            },
            onError: handleError
        });
    };
    
    return(
        <Box>
            <APTable columns={colDef} actions={actions} data={tableData} isLoading={isLoading} />
            <DeleteConfirmationDialog
                    title="Move to trash"
                    content={'Are you sure you want to move this organization to trash?'}
                    open={openDeleteDialog}
                    onCancel={handleCancelDelete}
                    onDelete={handleOnDelete}
                    isDeleting={isOrgDeleting}
                />
        </Box>
    )
}

export default OrganizationTable;