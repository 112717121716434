import { persist } from 'zustand/middleware';
import { create } from 'zustand';
import { ILoginActionResponse, IUser } from './authInterfaces';

interface AuthStore {
  isLoggedIn: boolean;
  token: string | null;
  authUser: IUser | null;
}

interface AuthAction {
  storeLogin: (data: ILoginActionResponse) => void;
  clearLogin: () => void;
}

const initialState: AuthStore = {
  isLoggedIn: false,
  token: null,
  authUser: null,
};

export const useAuthStore = create<AuthStore & AuthAction>()(
  persist(
    (set) => ({
      ...initialState,
      storeLogin: (response: ILoginActionResponse) =>
        set(() => {
          localStorage.setItem('token',response.token);
          return {
            isLoggedIn: true,
            token: response.token,
            authUser: response.user,
          };
        }),
      clearLogin: () =>
        set(() => {
          return initialState;
        }),
    }),
    {
      name: 'auth-storage'
    }
  )
);
