import * as React from 'react';
import Box from '@mui/material/Box';
import Select, {StylesConfig} from 'react-select';
import makeAnimated from 'react-select/animated';
import { Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

const animatedComponents = makeAnimated();

const styles: StylesConfig<unknown,true> = {
  control: (styles) => ({ ...styles, minHeight:'42px' }),
}

interface Props{
  id?: string;
  label: string;
  name: string;
  control: Control<any>
  defaultValue?: string;
  isMultiple?: boolean;
  rules?: object;
  options: any[];
}

const SelectInput:React.FC<Props> = ({id, label, name, control, defaultValue='', isMultiple, options, rules}) => {
  return (
    <Box sx={{ mb:2, width:'100%'}}>
      <Typography component="label" htmlFor={id} sx={{ mb: 1, fontSize:'12px', color:'#4F4F4F' }}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <Box>
            <Select
              {...field}
              id={id}
              components={animatedComponents}
              options={options}
              isMulti={isMultiple}
              styles={styles}
            />
            {fieldState.error && (
              <Typography variant="body2" color="error" sx={{ mt: '4px',mx:'14px', fontSize:'12px' }}>
                {fieldState.error.message}
              </Typography>
            )}
          </Box>
        )}
      />
    </Box>
  );
}

export default SelectInput;
