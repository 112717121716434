import { Box, CircularProgress, Modal, Typography } from "@mui/material";

interface Props {
    title?:string;
    content?:string;
    open: boolean;
    onClose?: () => void;
}

const LoadingAction: React.FC<Props> = ({title, content, open, onClose}) => {
    return (
        <Modal open={open}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 2,
                borderRadius: 2,
                }}
            >
                <Box sx={{display:'flex', alignItems:'center', gap:'30px'}}>
                    <Typography variant="h6" component="h2">{title}</Typography>
                    <CircularProgress size={24}/>
                </Box>
                <Typography sx={{ mt: 2 }}>
                {content}
                </Typography>
            </Box>
        </Modal>
    )
}

export default LoadingAction;