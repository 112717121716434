import { Avatar, Box, Typography } from "@mui/material";
import { ITableImageText } from "./tableInterfaces";

interface Props{
    data?: ITableImageText;
}
const APImageText:React.FC<Props> = ({data}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', height:'100%', gap: 1 }}>
            <Avatar alt={data?.label} src={data?.image} sx={{height:'32px', width:'32px'}}/>
            <Typography>{data?.label}</Typography>
        </Box>
    )
}

export default APImageText;