import { Toolbar, Box, Typography, styled, BadgeProps } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Badge } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { DRAWER_WIDTH } from "../../../Constant";
import { NotificationsNoneOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useAuthStore } from "../../../features/auth/authStore";

interface Props{
    open: boolean;
    handleDrawerOpen: () => void;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(({ theme, open }) => ({
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));
  
    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
      '& .MuiBadge-badge': {
        right: -3,
        top: -2,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
      },
    }));

    const CustomAppBar: React.FC<Props> = ({ open, handleDrawerOpen }) => {
        const location = useLocation();
        const authUser = useAuthStore(state => state.authUser);
    
        // Function to convert pathname to a title
        const formatTitle = (path: string) => {
    // Split the path by '/' and filter out any empty segments
    const segments = path.split('/').filter(Boolean);

    if (segments.length === 0) return 'Dashboard'; // If it's the root path, return 'Dashboard'

    // Extract the last segment
    const lastSegment = segments[segments.length - 1];

    // Check if the last segment is a dynamic ID (alphanumeric string of 16 characters)
    const isDynamicSegment = /^[A-Za-z0-9]{16}$/.test(lastSegment);

    // If it's a dynamic segment, remove it
    if (isDynamicSegment) {
        segments.pop();
    }

    // Rejoin the remaining segments to form the path to be formatted
    const formattedPath = segments.join('-').replace(/-/g, ' ');

    // Capitalize the first character and return
    return formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1);
};
    
        const pageTitle = formatTitle(location.pathname);
    
        return (
            <AppBar
                position="fixed"
                elevation={0}
                open={open}
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.divider_color}`,
                    backgroundColor: (theme) => theme.palette.white,
                    color: (theme) => theme.palette.black,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography sx={{fontSize:'32px', fontWeight:'500', color:(theme)=>`${theme.palette.black}`}}>{pageTitle}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Avatar alt={authUser?.name} src={authUser?.name} />
                                <Typography sx={{ fontSize: '13px' }}>{authUser?.name}</Typography>
                            </Box>
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                                sx={{
                                    backgroundColor: (theme) => `${theme.palette.black}15`,
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '100%',
                                }}
                            >
                                <StyledBadge badgeContent={0} color="error">
                                    <NotificationsNoneOutlined sx={{ fontSize: '22px' }} />
                                </StyledBadge>
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    };

export default CustomAppBar;