import { ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { INavigation } from "./navigationInterfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { MaterialSymbol } from "react-material-symbols";

interface Props{
    item: INavigation;
}
const NavigationItem:React.FC<Props> = ({item}) => {
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();

    const isActive = () => {
        if (location.pathname === item.path) {
            return true;
        }
        
        if (item.children) {
            return item.children.some(child => {
                return location.pathname.startsWith(child) && 
                       (location.pathname === child || location.pathname.length > child.length);
            });
        }

        return false;
    };

    const handleNavigation = (path: string) => {
        navigate(path);
    };
    return(
        <ListItem key={item.name} disablePadding>
            <ListItemButton
                onClick={() => handleNavigation(item.path)}
                sx={{
                    backgroundColor: isActive() ? theme.palette.primary.main : 'inherit',
                    color: isActive() ? theme.palette.white : '#212B36',
                    '&:hover': {
                        backgroundColor: isActive()
                            ? theme.palette.primary.main + 'E6' // 90% opacity
                            : theme.palette.action.hover,
                    },
                }}
            >
                <ListItemIcon
                    sx={{
                    color: isActive() ? theme.palette.white : '#212B36',
                    opacity:isActive() ?1:0.6
                    }}
                >
                    <MaterialSymbol
                        icon={item.icon}
                        size={24}
                    />
                </ListItemIcon>
                <ListItemText primary={item.name} />
            </ListItemButton>
        </ListItem>
    )
}

export default NavigationItem;