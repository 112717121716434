export const isRequired = (value: string) => 
    value ?true : `This field is required`;
  
export const isEmail = (value: string) => 
  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) || 'Invalid email format';

export const isUpperCase = (value: string) => 
  /[A-Z]/.test(value) || 'Must contain an uppercase letter';

export const isLowerCase = (value: string) => 
  /[a-z]/.test(value) || 'Must contain a lowercase letter';

export const hasNumber = (value: string) => 
  /\d/.test(value) || 'Must contain a number';

export const hasSpecialChar = (value: string) => 
  /[@$!%*?&]/.test(value) || 'Must contain a special character';

export const isMinLength = (minLength: number) => (value: string) => 
  value.length >= minLength || `Must be at least ${minLength} characters long`;

export const isPasswordMatch = (password: string) => (value: string) => 
  value === password || 'Passwords must match';
  