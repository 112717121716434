import { Box } from "@mui/material";
import { IColDef } from "../../components/common/table/tableInterfaces";
import APTable from "../../components/common/table/APTable";
import { ASSETS_DATA } from "../../data/assetsData";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const colDef: IColDef[] = [
    { field: 'valuationType', headerName: 'Valuation type', type: 'imageText2', filterable:false },
    { field: 'year', headerName: 'Year', filterable:false, width:100 },
    { field: 'make', headerName:'Make', filterable:false},
    { field: 'model', headerName: 'Model', filterable:false},
    { field: 'assetType', headerName: 'Asset type', filterable:false },
    { field: 'serial', headerName: 'Serial',filterable:false },
    { field: 'stickerId', headerName: 'Sticker ID',filterable:false },
    { field: 'myEstimate', headerName: 'My estimate ($)',filterable:false, width:130 },
    { field: 'otherEstimates', headerName: 'Other estimates ($)', type:'imageBadge',filterable:false },
    { field: 'actions', headerName: 'Actions', type: 'action',filterable:false, width:130 }
];


const AssetsTable = () => {
    // console.log(ASSETS_DATA)
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = useState<any>();
    const [openAdditionalInfoModal, setOpenAdditionalInfoModal] = useState(false);
    const actions = [
        { label: 'Request additional info', action: (row:any) => {
            setSelectedRow(row);
            setOpenAdditionalInfoModal(true);
        } },
        { label: 'Compare with others', action: (row:any) => {
            navigate(`/compare/${row.id}`);
        } },
        { label: 'Report', action: (row:any) => {
        } },
        { label: 'Edit asset', action: (row:any) => {
        } },
        { label: 'MOVE ASSET TO TRASH', action: (row:any) => {
           
        } }
    ];

    const handleOnCancel = () => {
        setSelectedRow(null);
        setOpenAdditionalInfoModal(false);
    }
    return (
        <Box>
            <APTable 
                columns={colDef}
                data={ASSETS_DATA}
                actions={actions}
            />
            <AdditionalInfoModal open={openAdditionalInfoModal} row={selectedRow} onCancel={handleOnCancel}/>
        </Box>
    )
}

export default AssetsTable;