import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";

const ATPTableToolbar = () => {
    return(
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector
                slotProps={{ tooltip: { title: 'Change density' } }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarExport
                slotProps={{
                tooltip: { title: 'Export data' },
                button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    )
}

export default ATPTableToolbar;