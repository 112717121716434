import { Box, useTheme } from "@mui/material";

interface Props {
  status: string | boolean;
}

const APStatus: React.FC<Props> = ({ status }) => {
  const theme = useTheme();

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const getStatus = (() => {
    if(!status) return {};
    if (typeof status === "boolean") {
      return {
        color: status ? theme.palette.completed_color : theme.palette.secondary.main,
        icon: status ? "checkmark-filled" : "close",
        text: status ? "Active" : "Inactive",
      };
    }

    switch (status.toLowerCase()) {
      case "new":
        return {
          color: theme.palette.new_color,
          icon: "not-started",
          text: "New"
        };
      case "in progress":
      case 'pending':
        return {
          color: theme.palette.in_progress_color,
          icon: "in-progress",
          text: capitalizeFirstLetter(status)
        };
      case "awaiting feedback":
        return {
          color: theme.palette.awaiting_feedback_color,
          icon: "pending-filled",
          text: "Awaiting feedback"
        };
      case "completed":
      case "active":
        return {
          color: theme.palette.completed_color,
          icon: "checkmark-filled",
          text: capitalizeFirstLetter(status)
        };
      case "blocked":
        return {
          color: theme.palette.secondary.main,
          icon: "close",
          text: "Blocked"
        };
      case "locked":
        return {
          color: '#E48731',
          icon: "locked",
          text: "Locked"
        };
      default:
        return {
          text: capitalizeFirstLetter(String(status))
        };
    }
  })();

  return (
    <Box
      sx={{
        backgroundColor: getStatus.color,
        color: status === "New" ? "" : "#ffffff",
        display: "flex",
        alignItems: "center",
        gap: "6px",
        width: "fit-content",
        padding: "0px 8px",
        borderRadius: "6px",
        height: "24px",
      }}
    >
      <img
        src={`/assets/icons/${getStatus.icon}.png`}
        width={"14px"}
        height={"14px"}
        alt={getStatus.text}
      />
      {getStatus.text}
    </Box>
  );
};

export default APStatus;
