import { Box } from "@mui/material";
import APTable from "../../components/common/table/APTable";
import { IColDef } from "../../components/common/table/tableInterfaces";
import { useEffect, useState } from "react";
import { ITableFilter } from "../../interfaces";
import { useMutation } from "@tanstack/react-query";
import { forgotPassword } from "../auth/authQueries";
import { IForgotPasswordForm, IUser } from "../auth/authInterfaces";
import { IUserForm } from "./userInterfaces";
import { postResentInvitation, updateUser } from "./userQueries";
import { handleError } from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { LoadingAction } from "../../components/common/dialogs";

const colDef: IColDef[] = [
    { field: 'name', headerName: 'Name', type: 'imageText', width:340},
    { field: 'role', headerName: 'Role', width:140},
    { field: 'email', headerName: 'Email', filterable:false },
    { field: 'status', headerName: 'Status', type: 'status', width:140 },
    { field: 'created_at', headerName: 'Date added', type: 'date',width:200 },
    { field: 'actions', headerName: 'Actions', type: 'action',filterable:false, width:140 }
];

interface Props{
    isLoading?:boolean;
    tableData?:IUser[];
    tableFilters?: ITableFilter[];
    hideActions?:boolean;
    onRefetch?: ()=>void;
    onRowCheck?:(item:any,details:any)=>void;
    toggleModal?:(state:boolean) => void;
    onOpenForm?:(row:any) => void;
    onDelete?:(row:any)=>void;
}

const UserTable: React.FC<Props> = ({isLoading,tableFilters,tableData=[],hideActions,onRefetch, onRowCheck, toggleModal, onOpenForm, onDelete}) => {
    // const [tableData, setTableData] = useState([]);
    const [optTitle,setOptTitle] = useState('');
    const [optContent,setOptContent] =useState('');

    const actions = [
        { label: 'Edit details', action: (row:any) => {
            if(onOpenForm)onOpenForm(row);
        } },
        { label: 'Resend invite', action: (row:any) => {
            setOptTitle('Resend Invitation');
            setOptContent('Sending invite. Please wait...');
            resendInviteMutation({email:row.email});
        } },
        { label: 'Block/Unblock user', action: (row:any) => {
            if(row.status === 'blocked'){
                setOptTitle('Unblock User');
                setOptContent('Unblocking user. Please wait...');
                updateUserMutation({ userId: row.id, formValues: {status:'active'} });
            }
            else {
                setOptTitle('Block User');
                setOptContent('Blocking user. Please wait...');
                updateUserMutation({ userId: row.id, formValues: {status:'blocked'} })
            }
        } },
        { label: 'Reset password', action: (row:any) => {
            setOptTitle('Reset Password');
            setOptContent('Sending reset password email. Please wait...');
            forgotPasswordMutation({email:row.email});
        } },
        { label: 'MOVE USER TO TRASH', action: (row:any) => {
            if(!onDelete) return;

            onDelete({
                content:'Are you sure you want to move this user to trash?',
                row
            })
        } }
    ];

    const { isPending: isResendInvite, mutate: resendInviteMutation } = useMutation({
        mutationKey: ['resend-invite-user'],
        mutationFn: (formValues: IUserForm ) =>
          postResentInvitation(formValues),
        onSuccess: () => {
          toast.success('Invitation send successfully');
        },
        onError: handleError,
    });
    
    const {isPending: isSendingForgotPassword, mutate: forgotPasswordMutation} = useMutation({
        mutationKey: ['forgotPassword'],
        mutationFn: (formValues: IForgotPasswordForm) => forgotPassword(formValues),
        onSuccess: () => {
            toast.success('Email send successfully');
            onRefetch?.();
          },
          onError: handleError,
    });
    const { isPending: isUpdating, mutate: updateUserMutation } = useMutation({
        mutationKey: ['update-user'],
        mutationFn: ({ userId, formValues }: { userId: string, formValues: IUserForm }) =>
          updateUser(userId, formValues),
        onSuccess: () => {
          toast.success('User updated successfully');
          onRefetch?.();
        },
        onError: handleError,
    });

    useEffect(()=>{
        if(toggleModal)toggleModal(isSendingForgotPassword||isUpdating)
    },[isSendingForgotPassword, isUpdating, toggleModal]);

    return (
        <Box>
            <APTable 
                columns={colDef} 
                data={tableData} 
                isLoading={isLoading} 
                actions={actions} 
                filterItems={tableFilters}
                showCheckbox
                onRowSelection={onRowCheck}
                hideActions={hideActions}
            />
            <LoadingAction open={isUpdating||isResendInvite||isSendingForgotPassword} title={optTitle} content={optContent}/>
        </Box>
    )
}

export default UserTable;