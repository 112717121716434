import { Button, CircularProgress } from "@mui/material";

interface Props{
    text: string;
    type?: "button" | "submit" | "reset" | undefined;
    isLoading?: boolean;
    disable?:boolean;
    onClick?: () => void;
}

const DefaultButton: React.FC<Props> = ({text, type='button', isLoading, disable=false, onClick}) => {
    return (
        <Button
            type={type}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, height: '45px' }}
            disabled={isLoading||disable}
            startIcon={isLoading ? <CircularProgress color="inherit" size={20} sx={{marginRight:1}} /> : null}
            onClick={onClick}
            >
            {text}
        </Button>
    )
}

export default DefaultButton;