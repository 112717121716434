import { Box } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import CustomAppBar from "../components/common/appbar/AppBar";
import AppNavigation from "../components/common/navigation/AppNavigation";
import MainContent from "../components/common/MainContent";
import DrawerHeder from "../components/common/appbar/DrawerHeader";
import { APLoader } from "../components/common/loaders";
import { useGeneralStore } from "../generaStore";
import useInactivityLogout from "../hooks/useInactivityLogout";
import { LogoutWarningModal } from "../components/common/dialogs";

const AppLayout = () => {
    const [open, setOpen] = useState(true);
    const isPageTransition = useGeneralStore(state => state.isPageTransition);
    const { showWarning, resetTimer } = useInactivityLogout(600000, 300000);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        if(isPageTransition) return;
        setOpen(false);
    };
    return(
        <Box sx={{width:'100%', height:'100vh'}}>
            {isPageTransition && <APLoader />}
            <CustomAppBar open={open} handleDrawerOpen={handleDrawerOpen}/>
            <AppNavigation open={open} handleDrawerClose={handleDrawerClose}/>
            <MainContent open={open}>
                <DrawerHeder />
                <Outlet />
                <LogoutWarningModal
                    open={showWarning}
                    onClose={resetTimer}
                    onStayLoggedIn={resetTimer}
                />
            </MainContent>
        </Box>
    )

}

export default AppLayout;