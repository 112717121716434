import React, { useState } from 'react';
import { Box, Typography} from '@mui/material';
import * as XLSX from 'xlsx';
import { Control, Controller } from 'react-hook-form';
import APFileModal from './ExcelFileInput/APFileModal';
import APFileUploaded from './ExcelFileInput/APFileUploaded';
import APDragAndDrop from './ExcelFileInput/APDragAndDrop';
import { toast } from 'react-toastify';

interface ExcelFileInputProps {
    id?: string;
    label?: string;
    name: string;
    control:Control<any>;
    defaultValue?: File;
    rules?: object;
    width?:string;
}

const ExcelFileInput: React.FC<ExcelFileInputProps> = ({ id, label, name, control, defaultValue = null, rules, width='100%' }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (file: File) => {
        setSelectedFile(file);
        processFile(file);
    };

    const processFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const worksheet = workbook.Sheets['Catalog Import'];
            if (worksheet) {
                const headers: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
                const json: any = XLSX.utils.sheet_to_json(worksheet, { defval: null });
                processTableData(headers, json);
            } else {
                toast.error('Catalog Import sheet not found.');
            }
        };
        reader.readAsBinaryString(file);
    };

    const processTableData = (headers: string[], data: any[]) => {
        const col = headers.map((header) => ({
            field: header,
            headerName: header,
        }));

        const rowData = data.map((row, index) => ({
            id: index,
            ...row,
        }));

        setColumns(col);
        setTableData(rowData);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setColumns([]);
        setTableData([]);
        setSelectedFile(null);
    };

    const handleCancelSelection = () => {
        setColumns([]);
        setTableData([]);
        setFileUploaded(false);
        setSelectedFile(null);
    };

    const handleUploadAssets = (onChange: (data: { filename: string; columns: any[]; tableData: any[] }) => void) => {
        setFileUploaded(true);
        setModalOpen(false); // Close modal after uploading

        // Prepare JSON object to return
        const jsonData = {
            filename: selectedFile?.name || '',
            columns,
            tableData,
        };

        // Call the onChange function if provided
        if (onChange) {
            onChange(jsonData);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field, fieldState }) => (
                <Box>
                    {!fileUploaded ? (
                        <Box id={id}>
                            <APDragAndDrop 
                                label='Drag and drop or choose an Excel file'
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                                handleFileChange={handleFileChange}
                            />
                            {fieldState.error && (
                                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                    {fieldState.error.message}
                                </Typography>
                            )}
                        </Box>
                    ) : (
                        <Box sx={{width:width}}>
                            <APFileUploaded selectedFile={selectedFile!} handleCancelSelection={handleCancelSelection}/>
                        </Box>
                    )}

                    <APFileModal 
                        modalOpen={modalOpen}
                        handleModalClose={handleModalClose}
                        tableData={tableData}
                        columns={columns}
                        handleUploadAssets={()=>handleUploadAssets(field.onChange)}
                    />
                </Box>
            )}
        />
    );
};

export default ExcelFileInput;
