import { Box, Checkbox, FormControlLabel, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { MaterialSymbol } from "react-material-symbols";
import { APIconButton } from "../../components/common/buttons";
import { ASSET_INFO_DATA } from "../../data/assetInfoData";

interface Props{
    open:boolean;
    row:any;
    onCancel: ()=>void;
}

const AdditionalInfoModal: React.FC<Props> = ({open,row, onCancel}) => {
    const [selectedFields, setSelectedFields] = useState<string[]>([]);

    const handleCheckboxChange = (field: string) => {
        if (selectedFields.includes(field)) {
            setSelectedFields(selectedFields.filter((f) => f !== field));
        } else {
            setSelectedFields([...selectedFields, field]);
        }
    };

    return(
        <Modal open={open}>
            <Box
                sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 700,
                height:'80vh',
                bgcolor: 'background.paper',
                borderRadius: 2,
                boxShadow: 24,
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            backgroundColor: '#FCFCFC',
                            p: '25px',
                            borderRadius: '8px 8px 0px 0px',
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: '#000000' }}>
                            Request info for:
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box>
                                <APIconButton
                                    text="Cancel"
                                    icon={<MaterialSymbol icon="close" />}
                                    onClick={onCancel}
                                    whiteBackground
                                />
                            </Box>
                            <APIconButton
                            text={'Send request'}
                            icon={<MaterialSymbol icon="trending_flat" />}
                            type="submit"
                            isLoading={false}
                            />
                        </Box>
                    </Box>
                    
                    <Box sx={{ 
                        display:'flex',
                        p:'20px 25px', 
                        flexDirection:'column',
                        height:'70vh',
                        overflow:'auto'

                    }}>
                        {ASSET_INFO_DATA.map((data) => (
                            <Box key={data} sx={{
                                borderBottom:`1px solid ${'#000000'}10`,
                                backgroundColor:'#F9FBFE',
                                padding:'16px 8px 16px 32px',
                            }}>
                                <FormControlLabel
                                    key={data}
                                    control={
                                        <Checkbox
                                            checked={selectedFields.includes(data)}
                                            onChange={() => handleCheckboxChange(data)}
                                        />
                                    }
                                    label={data}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default AdditionalInfoModal;