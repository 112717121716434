import { Box, Button } from "@mui/material";
import { useState } from "react";

interface Props {
    filters: string[],
    onStatusClick: (filter: string) => void;
}

const APStatusFilter: React.FC<Props> = ({ filters, onStatusClick }) => {
    const [activeFilter, setActiveFilter] = useState<string>(filters[0]);

    const handleButtonClicked = (filter: string) => {
        setActiveFilter(filter);
        onStatusClick(filter);
    };

    return (
        <Box
            sx={{
                backgroundColor: '#F2F2F2',
                width: 'fit-content',
                padding: '4px',
                borderRadius: '8px',
            }}
        >
            {filters.map((filter: string) => (
                <Button
                    key={filter}
                    sx={{
                        backgroundColor: activeFilter === filter ? '#fff' : '#F2F2F2',
                        color: activeFilter === filter ? '#000' : '#86888A',
                        height: '28px',
                        borderRadius: '6px',
                        boxShadow: activeFilter === filter 
                            ? '0px 2px 2px rgba(0, 0, 0, 0.2)'
                            : 'none',
                    }}
                    onClick={() => handleButtonClicked(filter)}
                >
                    {filter}
                </Button>
            ))}
        </Box>
    );
}

export default APStatusFilter;
