export const DUMMY_REQUEST_DATA = {
    "ownerName": "Simon Bankole",
    "ownerEmail": "simon@bankos.com",
    "location": "Kansas",
    "reasons": [
        {
            "id": "id-1",
            "label": "Liquidation",
            "value": "Liquidation"
        }
    ],
    "notes": "<p>This is some dummy text another another test</p>",
    "primaryAppraiser": {
        "id": "id-1",
        "label": "Okatakyie Opoku-Badu",
        "value": "Okatakyie Opoku-Badu"
    },
    "collaborators": [
        {
            "id": "id-1",
            "label": "Okatakyie Opoku-Badu",
            "value": "Okatakyie Opoku-Badu"
        }
    ],
    "fileData": {
        "filename": "HW Import - Sauvage.xlsx",
        "columns": [
            {
                "field": "Inventory Tag",
                "headerName": "Inventory Tag"
            },
            {
                "field": "Year",
                "headerName": "Year"
            },
            {
                "field": "Make",
                "headerName": "Make"
            },
            {
                "field": "Model",
                "headerName": "Model"
            },
            {
                "field": "Asset Type/Description",
                "headerName": "Asset Type/Description"
            },
            {
                "field": "Serial",
                "headerName": "Serial"
            },
            {
                "field": "VIN",
                "headerName": "VIN"
            },
            {
                "field": "Sticker/ICN",
                "headerName": "Sticker/ICN"
            },
            {
                "field": "Unique ID",
                "headerName": "Unique ID"
            },
            {
                "field": "Miles",
                "headerName": "Miles"
            },
            {
                "field": "Hours",
                "headerName": "Hours"
            },
            {
                "field": "Subpackage",
                "headerName": "Subpackage"
            },
            {
                "field": "Notes",
                "headerName": "Notes"
            }
        ],
        "tableData": [
            {
                "id": 0,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Caterpiller",
                "Model": 120,
                "Asset Type/Description": "grader",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 85,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 1,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "2 wheel utility trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "for hauling mower around"
            },
            {
                "id": 2,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Sunflower",
                "Model": 1233,
                "Asset Type/Description": "disc",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 3,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "4 wheel utility trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "wood sides"
            },
            {
                "id": 4,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "fuel trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 5,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "3pt mower",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 6,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Caterpillar",
                "Model": 950,
                "Asset Type/Description": "loader",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 19456,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 7,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Polaris",
                "Model": "XP",
                "Asset Type/Description": "6 seat ranger",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 8,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Polaris",
                "Model": "500 EFI",
                "Asset Type/Description": "2 seat ranger",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 9,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Kawasaki",
                "Model": 610,
                "Asset Type/Description": "2 seat mule",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 10,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Honda",
                "Model": null,
                "Asset Type/Description": "4 wheeler",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 11,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "for 4 wheelers"
            },
            {
                "id": 12,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Vermeer",
                "Model": 928,
                "Asset Type/Description": "hyd rake",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 13,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "orange disc",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 14,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Garfield",
                "Model": null,
                "Asset Type/Description": "dirt mover",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 15,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Donahue",
                "Model": null,
                "Asset Type/Description": "gooseneck flat bed trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 16,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "pull stalk mower",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 17,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "3pt bale mover",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 18,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Oldergreen",
                "Model": null,
                "Asset Type/Description": "auger with gas motor",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 19,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "pickup box trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 20,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "pickup box trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 21,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "pickup box trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 22,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "seed tender",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "not on a trailer"
            },
            {
                "id": 23,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "4 wheeler trailer with spray tank",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "110 gal"
            },
            {
                "id": 24,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Baker",
                "Model": null,
                "Asset Type/Description": "9-shank ripper",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 25,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "8-row tool bar",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 26,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Flex King",
                "Model": null,
                "Asset Type/Description": "4 blade sweep",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 27,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "orange tillage implement",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 28,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "3pt push blade for New Holland 9030",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 29,
                "Inventory Tag": null,
                "Year": null,
                "Make": "John Deere",
                "Model": 4600,
                "Asset Type/Description": "2 way plow",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 30,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Crust Buster",
                "Model": "Speed King",
                "Asset Type/Description": "trailer",
                "Serial": null,
                "VIN": "4C9GN2337K2219221",
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 31,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "corn header trailer",
                "Serial": "07301230000003676",
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 32,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Case",
                "Model": 3430,
                "Asset Type/Description": "precision air drill",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 33,
                "Inventory Tag": null,
                "Year": null,
                "Make": "New Holland",
                "Model": "BR 7090",
                "Asset Type/Description": "baler",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 34,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Case",
                "Model": "1250 Early Riser",
                "Asset Type/Description": "corn planter",
                "Serial": "Y95007253",
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 35,
                "Inventory Tag": null,
                "Year": 2018,
                "Make": "Case",
                "Model": 7240,
                "Asset Type/Description": "combine",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": "776.4, 579.6",
                "Subpackage": null,
                "Notes": "engine has 776.42, threshing hrs 579.65"
            },
            {
                "id": 36,
                "Inventory Tag": null,
                "Year": 2009,
                "Make": "Case",
                "Model": "275 Magnum",
                "Asset Type/Description": "tractor",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 3585.6,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 37,
                "Inventory Tag": null,
                "Year": 2015,
                "Make": "Challenger",
                "Model": "Rogator RG1100B",
                "Asset Type/Description": "sprayer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 1134.6,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 38,
                "Inventory Tag": 1,
                "Year": 2002,
                "Make": "New Holland",
                "Model": "TV140",
                "Asset Type/Description": null,
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 465.8,
                "Subpackage": null,
                "Notes": "with loader & bucket"
            },
            {
                "id": 39,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "white fuel trailer",
                "Serial": null,
                "VIN": "585BT1226E004620",
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 40,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "white fuel trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 41,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "white fuel trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 42,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "tanker trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": 26560
            },
            {
                "id": 43,
                "Inventory Tag": null,
                "Year": 2013,
                "Make": "Farm King",
                "Model": null,
                "Asset Type/Description": "easy rake",
                "Serial": "CRFKS18",
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 44,
                "Inventory Tag": "#83",
                "Year": 20015,
                "Make": "Freightliner",
                "Model": null,
                "Asset Type/Description": "truck",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 61986.3,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 45,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Macdon",
                "Model": "M205",
                "Asset Type/Description": "swather",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 498.3,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 46,
                "Inventory Tag": null,
                "Year": 1997,
                "Make": "New Holland",
                "Model": 9030,
                "Asset Type/Description": null,
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 3233.1,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 47,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "1100 gal water tank with pump",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 48,
                "Inventory Tag": null,
                "Year": null,
                "Make": "H&S",
                "Model": 5114,
                "Asset Type/Description": "hi-captivity rake",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 49,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Case",
                "Model": "RB565",
                "Asset Type/Description": "Baler",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 50,
                "Inventory Tag": "#2",
                "Year": 2002,
                "Make": "New Holland",
                "Model": "TV140",
                "Asset Type/Description": null,
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 407.6,
                "Subpackage": null,
                "Notes": "with loader & bucket & pallet forks"
            },
            {
                "id": 51,
                "Inventory Tag": null,
                "Year": 2017,
                "Make": "Case",
                "Model": "135 Maxxium",
                "Asset Type/Description": "tractor",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 1030.4,
                "Subpackage": null,
                "Notes": "and L755 loader"
            },
            {
                "id": 52,
                "Inventory Tag": null,
                "Year": null,
                "Make": "John Deere",
                "Model": 450,
                "Asset Type/Description": "drill",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "for planting AIF"
            },
            {
                "id": 53,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "black fuel trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 54,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": "TC 3677",
                "Asset Type/Description": "flat bed hay trailer",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 55,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Rhino",
                "Model": "Legent",
                "Asset Type/Description": "batwing mower",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 56,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "12' box scraper",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 57,
                "Inventory Tag": null,
                "Year": null,
                "Make": "McFarlane",
                "Model": null,
                "Asset Type/Description": "orange harrow",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 58,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Ram",
                "Model": 3500,
                "Asset Type/Description": "cummins 4x4",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 59,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": null,
                "Asset Type/Description": "packing wheel for alfalfa",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 60,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": "MKX 13-114",
                "Asset Type/Description": "auger",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "for new bins"
            },
            {
                "id": 61,
                "Inventory Tag": null,
                "Year": null,
                "Make": null,
                "Model": "MKX 100-73",
                "Asset Type/Description": "auger",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": "for new bins"
            },
            {
                "id": 62,
                "Inventory Tag": "#84",
                "Year": 2015,
                "Make": "Freightliner",
                "Model": null,
                "Asset Type/Description": "truck",
                "Serial": null,
                "VIN": "3AKJGND62FDGH1835",
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 62874.4,
                "Subpackage": null,
                "Notes": 45549
            },
            {
                "id": 63,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Timpte",
                "Model": null,
                "Asset Type/Description": "grain trailer",
                "Serial": "H42227 2B100461",
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": 45383
            },
            {
                "id": 64,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Case",
                "Model": "Steiger 335",
                "Asset Type/Description": "tractor",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 1765.7,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 65,
                "Inventory Tag": "#78",
                "Year": 1980,
                "Make": "Mack",
                "Model": "CH613",
                "Asset Type/Description": null,
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 321048.8,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 66,
                "Inventory Tag": "#76",
                "Year": 1980,
                "Make": "Mack",
                "Model": "CH613",
                "Asset Type/Description": null,
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": 185530,
                "Subpackage": null,
                "Notes": null
            },
            {
                "id": 67,
                "Inventory Tag": null,
                "Year": null,
                "Make": "Caterpillar",
                "Model": null,
                "Asset Type/Description": "generator",
                "Serial": null,
                "VIN": null,
                "Sticker/ICN": null,
                "Unique ID": null,
                "Miles": null,
                "Hours": null,
                "Subpackage": null,
                "Notes": null
            }
        ]
    }
}