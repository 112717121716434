import { Box, Typography } from "@mui/material";
import CallMadeIcon from '@mui/icons-material/CallMade';

interface Props{
    value?: string;
}
const APMediaCell:React.FC<Props> = ({value}) => {
    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 1
        }}>
            <Typography>{value}</Typography>
            <CallMadeIcon sx={{ fontSize: '16px', opacity: 0.6 }} />
        </Box>
    )
}

export default APMediaCell;