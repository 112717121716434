import { Box, Typography } from "@mui/material";
import { DASHBOARD_CARD_DATA } from "../../data/dashboardData";
import DashboardCard from "./DashbardCard";
import APStatusFilter from "../../components/common/APStatusFilter";
import AddIcon from '@mui/icons-material/Add';
import { APIconButton } from "../../components/common/buttons";
import DashboardTable from "./DashboardTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TABLE_DATA } from "../../data/tableData";
import { getDashboardData } from "./dashboardQueries";
import { useQuery } from "@tanstack/react-query";
import { useGeneralStore } from "../../generaStore";

const DashboardPage = () => {
    const navigate = useNavigate();
    const setIsPageTransition = useGeneralStore(state=>state.setIsPageTransition);
    const filters: string[] = ['All', 'In progress', 'Awaiting feedback', 'Completed'];
    const [data, setData] = useState(TABLE_DATA.slice(0,5));
    const handleFilterClicked  = (filter:string) => {
        const filteredData = filter ==='All'?TABLE_DATA:TABLE_DATA.filter((item)=>item.status === filter);
        setData(filteredData);
    } 
    const {isPending: isLoading, data:response} = useQuery({ queryKey: ['dashboard'], queryFn: getDashboardData, refetchOnWindowFocus:false })

    useEffect(()=>{
        setIsPageTransition(isLoading)
        
        return () => {
            setIsPageTransition(false);
          };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoading]);

    return(
        <Box sx={{width:'100%',padding:theme=> theme.spacing(3)}}>
            <Box>
                <Box>
                    <Typography sx={{fontWeight:'bold', fontSize:'24px'}}>Updates</Typography>
                    <Typography sx={{fontSize:'14px', opacity:0.6}}>Get your updates right here on your dashboard!</Typography>
                    <Box sx={{
                        display:'flex',
                        width:'100%',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginTop:'20px'
                    }}>
                        {
                            DASHBOARD_CARD_DATA.map((item:any)=> (
                            <DashboardCard 
                                key={item.status}
                                status={item.status} 
                                total_request={item.total_requests} 
                                total_assets={item.total_assets}
                                onClick={(filter)=>navigate('/requests',{state:{filter}})} 
                            />
                        ))
                        }
                    </Box>
                </Box>
                <Box sx={{marginTop:'40px', marginBottom:'30px'}}>
                    <Typography sx={{fontWeight:'bold', fontSize:'24px'}}>Requests received</Typography>
                    <Typography sx={{fontSize:'14px', opacity:0.6}}>This is a list of all requests you’ve received.</Typography>
                </Box>
                <Box>
                    <Box sx={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        marginBottom:'10px'
                    }}>
                        <APStatusFilter filters={filters} onStatusClick={handleFilterClicked} />
                        <Box>
                            <APIconButton 
                                text="New Request"
                                onClick={()=>{navigate('/new-request')}}
                                icon={<AddIcon />}
                            />
                        </Box>
                    </Box>
                    <Box sx={{height:370}}>
                        <DashboardTable data={response?.data??data}/>
                    </Box>
                </Box>
            </Box>
            
        </Box>
    );

}

export default DashboardPage;