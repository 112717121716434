export const DASHBOARD_CARD_DATA = [
    {
        status: 'New',
        total_requests:10,
        total_assets:32    
    },
    {
        status: 'In progress',
        total_requests:15,
        total_assets:302    
    },
    {
        status: 'Awaiting feedback',
        total_requests:0,
        total_assets:0    
    },
    {
        status: 'Completed',
        total_requests:149,
        total_assets:5009    
    }
];